import Vue from 'vue';
import * as _ from 'lodash';
const state = {
    userDetails: {
        personal: {
            label: 'Personal',
            spouse: true,
            values: {
                title: { value: '', type: 'text', label: 'Title' },
                forenames: { value: '', type: 'text', label: 'Forenames' },
                surname: { value: '', type: 'text', label: 'Surname' },
                gender: { value: '', type: 'text', label: 'Gender' },
                dateOfBirth: { value: '', type: 'date', label: 'Date Of Birth' },
                niNumber: { value: '', type: 'text', label: 'National Insurance Number' },
                postcode: { value: '', type: 'text', label: 'Postcode' },
            }
        },
        dependants: {
            label: 'Dependants',
            spouse: false,
            values: {
                dependants: { value: false, type: 'yesNo', label: 'Do you have any dependants?' }
            },
            rowIf: (level) => level.values.dependants.value,
            rowSchema: [
                { id: 'name', label: 'Name', type: 'text', value: '' },
                { id: 'age', label: 'Age', type: 'number', value: '' },
                { id: 'relationship', label: 'Relationship to you', type: 'text', value: '' },
                { id: 'dependant', label: 'Financially dependant', type: 'yesNo', value: false },
            ],
            rows: [
                [
                    { id: 'name', label: 'Name', type: 'text', value: '' },
                    { id: 'age', label: 'Age', type: 'number', value: '' },
                    { id: 'relationship', label: 'Relationship to you', type: 'text', value: '' },
                    { id: 'dependant', label: 'Financially dependant', type: 'yesNo', value: false }
                ]
            ]
        },
        income: {
            label: 'Income',
            spouse: true,
            values: {
                nationalGridIncome: { spouse: false, value: '', type: 'money', label: 'Cadent Gas income' },
                otherIncome: { value: '', type: 'money', label: 'Other income' },
                assets: { value: '', type: 'money', label: 'Assets' },
                investment: { value: '', type: 'money', label: 'Investments' },
                savings: { value: '', type: 'money', label: 'Total savings' },
                other: { value: '', type: 'money', label: 'Other' },
            }
        },
        expenditure: {
            label: 'Expenditure',
            spouse: false,
            values: {
                expenditure: { value: '', type: 'money', label: 'Estimated total monthly expenditure' },
                changes: { value: false, type: 'yesNo', label: 'Do you expect any changes in your expenditure in the foreseeable future?' },
                expenditureDetails: { showIf: (level) => level.values.changes.value, value: '', type: 'textarea', label: 'Please provide details including the anticipated timescale'}
            }
        },
        health: {
            label: 'Health',
            spouse: true,
            values: {
                smoke: { value: false, type: 'yesNo', label: 'Do you smoke?'},
                diagnosed: { value: '', type: 'text', label: 'Have you been diagnosed with serious ill health, or a life limiting condition?'},
                information: { value: '', type: 'textarea', label: 'Please provide any further information'}
            }
        },
        final: {
            label: 'Thank you'
        }

    },
    spouseDetails: null
};

const getters = {
    getUserDetails (state) {
        return state.userDetails;
    },
    getSpouseDetails (state) {
        return state.spouseDetails;
    }
};

const actions = {
    setUserDetails (context, details) {
        context.commit('setUserDetails', details);
    },
    setSpouseDetails (context, details) {
        context.commit('setSpouseDetails', details);
    }
};

const mutations = {
    setupSpouse (state) {
        if (!state.spouseDetails) {
            state.spouseDetails = {};
            for (var level in state.userDetails) {
                if (state.userDetails[level].spouse) {
                    Vue.set(state.spouseDetails, level, _.cloneDeep(state.userDetails[level]));
                }
            }
        }
    },
    setUserDetails (state, details) {
        state.userDetails = details;
    },
    setSpouseDetails (state, details) {
        state.spouseDetails = details;
    },
    setupFactfind (state, schemeName) {
        state.userDetails.income.values.nationalGridIncome.label = schemeName + ' income';
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
