<template>
    <div class="wrapper" @click.self="cancel">
        <div class="panelWrapper">
            <h1>Print document</h1>
            <div class="print-text" ref="printdoc">
                <div class="print">
                    <div class="print-header">
                        <div class="left">
                            {{printDate }}
                        </div>
                        <div class="user">
                            User: {{ user.pension_number }}
                        </div>
                    </div>
                    <intro-video />
                    <intro />
                    <options :whatShouldIDoNowVisible="true" :showAll="true"/>
                    <final-questions />
                    <wpsa v-if="showEndSection"/>
                    <div class="terms-container">
                        <terms/>
                    </div>
                    <div class="terms-container">
                        <assumptions />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button v-if="!printing" class="submit" @click="print">Print</button>
                <button v-if="!printing" class="submitNotagree submit" @click="cancel">Cancel</button>
                <div v-if="printing">Please wait while your print pdf is created</div>
            </div>
        </div>
    </div>
</template>

<script>
import IntroVideo from "../home/intro-video";
import Intro from "../home/intro";
import Options from "../home/options";
import FinalQuestions from "../home/final-questions";
import Wpsa from './../wpsa';
import terms from "./../common/terms";
import constants from './../../constants.js';
import moment from 'moment';
import assumptions from './../common/assumptions';

export default {
    name: 'print',
    components: {
        IntroVideo, Intro, Options, FinalQuestions, Wpsa, terms, assumptions
    },
    data () {
        return {
            printing: false,
            printDate: moment().format('DD/MM/YYYY HH:mm')
        };
    },
    methods: {
        print () {
            this.printing = true;
            var refdoc = this.$refs.printdoc.innerHTML;

            // var outerHTML = document.documentElement.outerHTML;

            // outerHTML = outerHTML.replace(/<noscript>[\s\S]*?<\/body>/, '<body class="printed">' + refdoc + '</body>');
            // outerHTML = outerHTML.replace(/href="/g, 'href="' + 'https://' + window.location.host + '/');
            // outerHTML = outerHTML.replace(/href='/g, 'href=\'' + 'https://' + window.location.host + '/');
            // outerHTML = outerHTML.replace(/src="/g, 'src="' + 'https://' + window.location.host + '/');
            // outerHTML = outerHTML.replace(/src='/g, 'src=\'' + 'https://' + window.location.host + '/');
            // var html = encodeURIComponent("<!DOCTYPE html5 >" + outerHTML);

            this.$store.dispatch('print', refdoc).then(() =>{
                this.$store.commit('print', false);
            });
            this.$store.dispatch('postAnalytics', { measure: constants.Link_Printed, value: new Date() });
        },
        cancel () {
            this.$store.commit('print', false);
        },
        getValue (prop) {
            return this.$store.getters.getValue(prop);
        },
        setValue (prop, value) {
            this.$store.dispatch("setValue", { prop, value });
        },
    },
    computed:{
        user () {
            return this.$store.getters.getUser;
        },
        showEndSection: {
            get () {
                return this.getValue('showEndSection');
            },
            set (val) {
                this.setValue('showEndSection', val);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './../../scss/variables.scss';
.wrapper {
    background-color: rgba(0,0,0,.4);
    bottom:0;
    left:0;
    position: fixed;
    right:0;
    top:0;
    z-index: 101;

    .panelWrapper {
        background-color: #fff;
        max-height: 80vh;
        margin-left: auto;
        margin-right: auto;
        margin-top: 75px;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        width: 70%;
        .statement {
            font-weight: bold;
        }
        h1 {
            font-size: 1.3rem;
            line-height: 45px;
            background: $nationalGridBlue;
            color: $nationalGridOrange;
            padding: 1rem;
        }
        .print-text {
            height: auto;
            overflow-x: hidden;
            overflow-y: scroll;
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				background-color: #F5F5F5;
				border-radius: 6px;
				box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
			}
			&::-webkit-scrollbar {
				background-color: #F5F5F5;
				width: 6px;
			}
			&::-webkit-scrollbar-thumb {
				-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
				background-color: $nationalGridBlue;
				border-radius: 6px;
				box-shadow: inset 0 0 6px rgba(0,0,0,.3);
			}
        }
        .submit {
            background: $nationalGridGreen;
            border-radius: 2px;
            clear: both;
            color: white;
            border: none;
            cursor: pointer;
            display: inline-block;
            padding: 10px;
            position: relative;
            text-align: center;
            z-index: 1;
            width: 100px;
            margin-right: 30px;
        }
        .submitNotagree {
            background: grey;
            color: white;
        }
    }
    .modal-footer {
        background: #f4f4f4;
        position: relative;
        padding: 1rem;
    }
}
@media only screen and (max-width : 768px) {
    .panel{
        .panelWrapper{
            width: 90%;
        }
    }
}

.terms-container {
    padding: 1rem;
    .terms-scroll {
        height: auto;
        overflow: visible !important;
        padding: 0;
    }
}

.print {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: rgba(0, 0, 0, 0.1);
        pointer-events: all;
        z-index: 999;
    }
}

.print-header {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 1rem;
}

.print ::v-deep {
    .video, .btn, .white-curve, .grey-curve,
    .options .options-grid .video.small,
    .options #whatdoesthismean .retirementAgeSlider,
    .sunshine, .option-count, .pipe, .end-of-pipe, .line,
    .yes-no, .question-title, .alsoConsider img, .final-white-curve,
    .wpsa .flow-diagram, .email-button, .final-questions img {
        display: none !important;
    }
    .intro {
        height: auto !important;
        p {
            background: none;
            color: #333;
            max-width: none;
        }
    }
    .final-questions {
        .summary.left .content > div:nth-child(2) {
            width: 45%;
        }
        .summary.right .content > div {
            width: 100%;
        }
        .summary.left .content > div:nth-child(1){
            width: 100%;
        }
    }

    .modal {
        position: relative;
        box-shadow: none;
        top: auto;
        overflow: visible;
        max-height: none;
        width: auto;
        left: auto;
        bottom: auto;
    }
    .wpsa {
        margin-top: -2rem;
        padding: 0;
        .terms {
            margin-top: -1rem;
        }
    }
    .options {
        background: #fff;
    }
    .intro-video {
        min-height: auto !important;
        background: #fff !important;
        p {
            color: #333 !important;
        }
    }
    .final-questions {
        margin-top: 2rem;
    }
    .options-you-have {
        margin-top: 1rem !important;
    }
    .terms-container {
        padding: 2rem 5rem 1rem 5rem;
    }
}
</style>
<style lang="scss">
    body.printed {
        overflow: visible;
        .print:after {
            background: none !important;
        }
    }
</style>