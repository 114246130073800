<template>
<modal title="Pension from an insurer" @close="$emit('close')" :shortIntro="!currentQuestion || currentQuestion == (refineOrNot === 0? 7 : 5)">
  <template slot="intro">
    <p class="last" v-if="currentQuestion && currentQuestion !== (refineOrNot === 0? 7 : 5)">Based on current insurance prices, you could expect a pension starting at age {{retirementAgeShow | age}} of about <span class="amount">£{{ annuityCalc.retirement_annuity | roundDown(100)}} </span> pa<span v-if="annuityCalc.retirement_pcls > 0"> and a cash lump sum of <span class="amount">£{{ annuityCalc.retirement_pcls | roundDown(500)}}</span></span></p>
  </template>
  <template slot="content">
    <questions-flow @complete="submit" ref="questions">
      <question title="Introduction" :animateText="true" :order="0">
        <div class="container left">
          <p class="animate">You may be able to transfer the value of your {{ schemeDetails.schemeName }} benefits as cash to an insurance company. In exchange of your transfer, you can customise a pension package with the insurance company that meets your needs (for example, you can decide how your pension increases each year and if you want any spouse’s pension payable on your death).  <b>If you want to take this option, you will need to take advice from an authorised financial adviser who will help you with this decision.</b></p>
          <p class="animate">As an illustration, based on current insurance prices and your transfer value of <span class="amount"> £{{annuityCalc.doc_tv | roundDown(1000)}}</span><span v-if="user.mp_avcs_value > 0"> (and your Money Purchase AVCs of £{{user.mp_avcs_value | roundDown(500)}})</span> as at {{user.calculation_date.format('MMMM YYYY')}}, you could expect a pension starting at age {{retirementAgeShow | age}} of around <span class="amount">£{{ annuityCalc.retirement_annuity | roundDown(100)}} pa</span><span v-if="annuityCalc.retirement_pcls > 0"> and a cash lump sum of <span class="amount">£{{ annuityCalc.retirement_pcls | roundDown(500)}}</span></span>. These figures are not guaranteed, will vary each month and may go up or down.</p>
          <p class="animate">You can see how this amount might change by refining your options below.</p>
        </div>
      </question>
      <question title="Do you want a pension that increases in line with the cost of living?" :animateText="true" :order="1">
        <div class="container left">
          <p class="animate">With an insurance pension you can choose how your pension increases in payment. Deciding which option might be best for you will depend on a number of things, including whether you have other sources of income, and how long you expect to live.</p>
          <p class="animate">You will have a number of choices.  There are two common illustrations: increasing and non increasing.</p>
          <p class="animate">For this illustration we have shown an annuity that increases each year in line with increases in the cost of living (known as inflation).</p>
          <p class="animate">You could buy an annuity that has no increases and remains at this level for the rest of your life.  With this option your initial retirement income will be higher but if the cost of living increases your pension will not.</p>
        </div>
      </question>
      <question title="Tax free cash lump sum" :animateText="true" :order="2">
        <div class="container left">
          <p class="animate">When you retire you can normally take up to 25% of the value of your pension for a cash lump sum. In many cases this can be taken tax free but this will depend on any other retirement benefits you are due to receive. Move the slider below to see how much you could receive as a lump sum and how this will affect your illustrated pension.</p>
          <div class="post-animate">
            <div>
              <vue-slider v-model="cashAmountPercentage" :real-time="true" :data="cashSliderDataPoints" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="0" :tooltip="false" :max="100" :dotSize="28">
                <template slot="label" slot-scope="{ label, active }">
                    <span class="vue-slider-piecewise-label" v-if="label === 0">Minimum</span>
                    <span class="vue-slider-piecewise-label" v-if="label === 100">Maximum</span>
                </template>
                <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 10 === 0"></span></template>
              </vue-slider>
            </div>
            <p class="slider-amount">Taking <span class="amount">£{{ annuityCalc.retirement_pcls | roundDown(500)}}</span> cash (which could have bought you £{{ annuityCalc.retirement_pcls_as_pension | roundDown(100)}} more pension per annum)</p>
          </div>
        </div>
      </question>
      <question title="Do you have dependants?" :animateText="true" :order="3">
        <div class="container left">
          <p class="animate">If you have dependants who won't have sufficient income after your death, you might want to consider a pension package that will help with this.</p>
          <p class="animate">With an insurance pension, you can decide on how much will be paid to your dependants after you die.  The higher the amount you choose, the lower your retirement income will be.</p>
          <p class="animate">For this illustration we have shown an annuity that would pay your spouse 66% of the pension you receive in the event of your death.</p>
        </div>
      </question>
      <question title="Summary" :animateText="true" v-if="refineOrNot === 1" :order="4">
        <div class="sub-groups">
          <div class="sub-group">
            <p class="animate sub-title left">Regular Income</p>
            <p class="animate left">A pension of £{{ annuityCalc.retirement_annuity | roundDown(100) }} pa payable from {{retirementAgeShow | age}} for the rest of your life <span v-if="increasingOrNot < 1">(increasing in line with increases in the cost of living)</span></p>
          </div>
          <div class="sub-group">
            <p class="animate sub-title left">Initial lump sum</p>
            <p class="animate left">An initial tax free lump sum of  £{{ annuityCalc.retirement_pcls | roundDown(500)}}  (in exchange of £{{ annuityCalc.retirement_pcls_as_pension | roundDown(100) }} pension pa)</p>
          </div>
          <div class="sub-group">
            <p class="animate sub-title left">Benefits for my dependants</p>
            <p class="animate left" v-if="spouseBenefit === 0">After your death, a pension of £{{ annuityCalc.retirement_sp_pen | roundDown(100) }} pa would be paid to your spouse or other nominated dependant.</p>
            <p class="animate left" v-else>None</p>
          </div>
        </div>

        <div class="options">
            <p>You can obtain further information on annuities, including those that pay higher income if you have a diagnosed medical condition or poor lifestyle, by visiting <a href="https://www.moneyhelper.org.uk/en/pensions-and-retirement/taking-your-pension/compare-annuities" target="_blank">How to shop around for an annuity - Money Advice Service</a> or by talking to your financial adviser.</p>
        </div>
        <div v-if="changed" class="changed">
            <p class="bold">You changed the following options</p>
            <ul>
                <li v-if="prechanges.spouseBenefit != spouseBenefit">Include spouses pension option from {{ prechanges.spouseBenefit === 0? 'yes' : 'no'}} to {{ spouseBenefit === 0? 'yes' : 'no'}}</li>
                <li v-if="prechanges.increasingOrNot != increasingOrNot">A pension that increases with the cost of living from {{ prechanges.increasingOrNot === 0? 'yes' : 'no'}} to {{ increasingOrNot === 0? 'yes' : 'no'}}</li>
                <li v-if="prechanges.cashAmountPercentage != cashAmountPercentage">Amount of tax free cash changed from {{prechanges.cashAmountPercentage/100 | percent}} to {{cashAmountPercentage/100 | percent}}</li>
                <li v-if="prechanges.smokingOrNot != smokingOrNot">Allowing for smoking from {{ prechanges.smokingOrNot === 0? 'yes' : 'no'}} to {{ smokingOrNot === 0? 'yes' : 'no'}}</li>
                <li v-if="prechanges.chronicOrNot != chronicOrNot">Allowing for a chronic health condition from {{ prechanges.chronicOrNot === 0? 'yes' : 'no'}} to {{ chronicOrNot === 0? 'yes' : 'no'}}</li>
            </ul>
            <p>Before these changes you had an illustrated pension of £{{prechanges.calcs.retirement_annuity | roundDown(100)}} pa <span v-if="prechanges.increasingOrNot" >(increasing in line with increases in the cost of living)</span>; an initial tax free lump sum of £{{ prechanges.calcs.retirement_pcls | roundDown(500)}}; and, a pension of £{{prechanges.calcs.retirement_sp_pen | roundDown(100)}} pa for your spouse after your death.</p>
        </div>
      </question>
      <question :title="'Refining your illustration'" :animateText="true" v-if="refineOrNot === 0" :order="5">
        <div class="container">
            <p class="animate left">Your estimate is based on your age and any additional information you have entered.  There are other factors that a provider may take into account too.</p>
            <p class="animate left">Your answers to these questions will only be used to illustrate the potential impact on your pension illustration and will not be recorded or passed on to any party.</p>
        </div>
      </question>
      <question :title="'Do you smoke?'" :animateText="true" v-if="refineOrNot === 0" :order="6">
        <div class="container">
          <div class="input-field post-animate">
            <div class="yes-no">
              <div class="option">
                <input id="smokes" type="radio" name="smoke" :value="0" v-model="smokingOrNot"/>
                <label for="smokes">Yes</label>
              </div>
              <div class="option">
                <input id="nosmokes" type="radio" name="smoke" :value="1" v-model="smokingOrNot"/>
                <label for="nosmokes">No</label>
              </div>
            </div>
          </div>
        </div>
      </question>
      <question :title="'Do you have a chronic health condition, such as diabetes or high blood pressure?'" :animateText="true" v-if="refineOrNot === 0" :order="7">
          <div class="container">
            <div class="input-field post-animate">
              <div class="yes-no">
                <div class="option">
                  <input id="chronic" type="radio" name="chronic" :value="0" v-model="chronicOrNot"/>
                  <label for="chronic">Yes</label>
                </div>
                <div class="option">
                  <input id="nonchronic" type="radio" name="chronic" :value="1" v-model="chronicOrNot"/>
                  <label for="nonchronic">No</label>
                </div>
              </div>
            </div>
          </div>
        </question>
      <question :title="'Refined Summary'" :animateText="true" v-if="refineOrNot === 0" :order="8">
        <div class="sub-groups">
          <div class="sub-group">
            <p class="animate sub-title left">Regular Income</p>
            <p class="animate left">A pension of £{{ annuityCalc.retirement_annuity | roundDown(100) }} pa payable from {{retirementAgeShow | age}} for the rest of your life <span v-if="increasingOrNot < 1">(increasing in line with increases in the cost of living)</span></p>
          </div>
          <div class="sub-group">
            <p class="animate sub-title left">Initial lump sum</p>
            <p class="animate left">An initial tax free lump sum of  £{{ annuityCalc.retirement_pcls | roundDown(500)}}  (in exchange of £{{ annuityCalc.retirement_pcls_as_pension | roundDown(100) }} pension pa)</p>
          </div>
          <div class="sub-group">
            <p class="animate sub-title left">Benefits for my dependants</p>
            <p class="animate left" v-if="spouseBenefit === 0">After your death, a pension of £{{ annuityCalc.retirement_sp_pen | roundDown(100) }} pa would be paid to your spouse or other nominated dependant.</p>
            <p class="animate left" v-else>None</p>
          </div>
        </div>
        <div v-if="changed" class="changed">
            <p class="bold">You changed the following options</p>
            <ul>
                <li v-if="prechanges.spouseBenefit != spouseBenefit">Include spouses pension option from {{ prechanges.spouseBenefit === 0? 'yes' : 'no'}} to {{ spouseBenefit === 0? 'yes' : 'no'}}</li>
                <li v-if="prechanges.increasingOrNot != increasingOrNot">A pension that increases with the cost of living from {{ prechanges.increasingOrNot === 0? 'yes' : 'no'}} to {{ increasingOrNot === 0? 'yes' : 'no'}}</li>
                <li v-if="prechanges.cashAmountPercentage != cashAmountPercentage">Amount of tax free cash changed from {{prechanges.cashAmountPercentage/100 | percent}} to {{cashAmountPercentage/100 | percent}}</li>
                <li v-if="prechanges.smokingOrNot != smokingOrNot">Allowing for smoking from {{ prechanges.smokingOrNot === 0? 'yes' : 'no'}} to {{ smokingOrNot === 0? 'yes' : 'no'}}</li>
                <li v-if="prechanges.chronicOrNot != chronicOrNot">Allowing for a chronic health condition from {{ prechanges.chronicOrNot === 0? 'yes' : 'no'}} to {{ chronicOrNot === 0? 'yes' : 'no'}}</li>
            </ul>
            <p>Before these changes you had an illustrated pension of £{{prechanges.calcs.retirement_annuity | roundDown(100)}} pa<span v-if="prechanges.increasingOrNot"> (increasing in line with increases in the cost of living)</span>; an initial tax free lump sum of £{{ prechanges.calcs.retirement_pcls | roundDown(500)}}; and, a pension of £{{prechanges.calcs.retirement_sp_pen | roundDown(100)}} pa for your spouse after your death.</p>
        </div>
      </question>
    </questions-flow>
  </template>
</modal>
</template>

<script>
import QuestionsFlow from './common/QuestionsFlow';
import Question from './common/Question';
import Modal from './common/Modal';
import Slider from './common/Slider';
import vueSlider from 'vue-slider-component';
export default {
    name: 'Annuity',
    components: { QuestionsFlow, Question, Slider, vueSlider, Modal },
    data () {
        return {
            questionNo: 0,
            mounted: false
        };
    },
    created () {
        this.prechanges = {
            spouseBenefit: this.spouseBenefit,
            increasingOrNot: this.increasingOrNot,
            cashAmountPercentage: this.cashAmountPercentage,
            smokingOrNot: this.smokingOrNot,
            chronicOrNot: this.chronicOrNot,
            calcs: this.annuityCalc
        };
    },
    mounted () {
        this.mounted = true;
    },
    methods: {
        submit () {
            this.$emit('submit');
        },
        refineOptions () {
            this.refineOrNot = 0;
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$refs.questions.gotoQuestionNoMovement(4);
                });
            });
        }
    },
    computed: {
        changed () {
            return this.prechanges.cashAmountPercentage != this.cashAmountPercentage || this.prechanges.spouseBenefit != this.spouseBenefit || this.prechanges.increasingOrNot != this.increasingOrNot || this.prechanges.smokingOrNot != this.smokingOrNot || this.prechanges.chronicOrNot != this.chronicOrNot;
        },
        user () {
            return this.$store.getters.getUser;
        },
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        },
        annuityCalc () {
            return this.$store.getters.getAnnuityCalc;
        },
        retirementAgeShow () {
            return this.$store.getters.getRetirementAge;
        },
        spouseBenefit: {
            get () {
                return this.$store.getters.getAnnuitySpouseOption? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuitySpouse', newValue === 0);
            }
        },
        increasingOrNot: {
            get () {
                return this.$store.getters.getAnnuityIncreasingOption? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuityIncreasing', newValue === 0);
            }
        },
        cashAmountPercentage: {
            get () {
                return this.$store.getters.getAnnuityCashMaxProp;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuityCashMaxProp', newValue);
            }
        },
        smokingOrNot: {
            get () {
                return this.$store.getters.getSmoking? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuitySmoking', newValue === 0);
            }
        },
        chronicOrNot: {
            get () {
                return this.$store.getters.getChronic? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuityChronic', newValue === 0);
            }
        },
        refineOrNot: {
            get () {
                return this.$store.getters.getRefine? 0 : 1;
            },
            set (newValue) {
                this.$store.dispatch('setAnnuityRefine', newValue === 0);
            }
        },
        cashSliderDataPoints () {
            var arr = [];
            for (var i = 0; i <= 100; i++) {
                arr.push(i);
            }
            return arr;
        },
        currentQuestion () {
            if (this.mounted && this.$refs.questions) {
                return this.$refs.questions.questionNo;
            }

        }
    }
};
</script>


<style scoped lang="scss">
@import './../scss/variables.scss';
@import './../scss/mixins.scss';
p.slider-amount {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 1rem;
    width: 100%;
    display: inline-block;
}

.amount {
    color: $nationalGridBlue;
    font-weight: 700;
}

.refine {
    text-align: center;
    position: relative;
    display: block;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    line-height: 1.5;
    font-size: 1rem;
    width: 320px;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    background: $nationalGridPink;
    color: white;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px;
    transition: transform 0.5s, box-shadow 0.5s;
    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
    }
}

input[type="radio"] {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}
input:checked {
    + .card {
        background: $nationalGridLightBlue;
        color: white;
        h4 {
            color: white;
        }
        .amount {
            color: white;
        }
    }
}
.cards {
    display: flex;
    @include responsive (mobile) {
        flex-wrap: wrap;
    }
}
.card {
    padding: 1rem;
    margin: 0.5rem;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px 1px;
    background: #fff;
    display: block;
    @include responsive (mobile) {
        width: 100%;
    }
    transition: transform 0.5s;
    &:hover {
        cursor: pointer;
        transform: scale(1.01);
    }
    p {
        padding: 0;
    }
    h4 {
        font-weight: 400;
        color: $nationalGridBlue;
        font-size: 1.4rem;
        line-height: 1.5;
    }
    &.selected {
        background: $nationalGridLightBlue;
        color: white;
        h4 {
            color: white;
        }
        .amount {
            color: white;
        }
    }
}

.yes-no {
    overflow: hidden;
    text-align: center;
    width: 100%;
    .option {
        display: inline-block;
    }
    input {
        opacity: 0;
        pointer-events: none;
        position: fixed;
        left: -9999px;
        &:not(:checked) ~ label {
            background: #ccc;
            box-shadow: none;
            transform: scale(1);
            z-index: 0;
        }
    }
    label {
        display: block;
        margin: 0;
        padding: 1rem 2rem;
        min-width: auto;
        width: 100%;
        background: $highlightColour;
        text-align: center;
        color: #fff;
        box-shadow: 1px 1px 2px rgba(0,0,0,.2);
        transform: scale(1.05);
        position: relative;
        transition: transform .25s;
        z-index: 1;
        &:hover {
            cursor: pointer;
        }
    }
}

.unit {
    display: inline-block;
    color: #999;
    font-size: 1.2rem;
    margin-left: 0.75rem;
}

.input-field {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}
.vue-slider-component.vue-slider-horizontal-reverse .vue-slider-piecewise-label, .vue-slider-component.vue-slider-horizontal .vue-slider-piecewise-label{
    margin-top:.8rem;
}
.changed{
    ul{
        list-style:disc;
        margin-left: 16px;
    }
    .bold{
        font-weight: bold;
    }
        padding: 0 20px;
    font-size: 1.1rem;
    text-align: left;
}
</style>
