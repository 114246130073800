import AuthenticationService from '../../lib/authenticationService';
import axios from 'axios';

let authService = null;

const state = {
    loggedIn: false,
    userInfo: null,
    token: '',
};

const getters = {
    loggedIn: (s) => s.loggedIn,
    token: (s) => s.token,
    userInfo: (s) => s.userInfo,
};

const actions = {
    async initialise (context, signup) {
        const config = {
            auth: {
                clientId: '642f7513-bd95-475e-af0e-67416df71dea',
                authority: 'https://cadentmyretirementplanner.b2clogin.com/cadentmyretirementplanner.onmicrosoft.com/' + (signup ? 'B2C_1_Cadent_Ext_nm_SignUp' : 'B2C_1_Cadent_Ext_nm_SignIn'),
                validateAuthority: false,
                knownAuthorities: ['cadentmyretirementplanner.b2clogin.com'],
                redirectUri: window.location.origin,
            },
            cache: {
                cacheLocation: 'localStorage',
            },
        };
        const scopes = {
            scopes: [
                'openid',
                'https://cadentmyretirementplanner.onmicrosoft.com/site/default.access',
            ],
        };
        authService = new AuthenticationService(config, scopes);

        return authService;
    },
    async login ({ dispatch }) {
        await dispatch('initialise', false);
        return authService.login();
    },
    async signup ({ dispatch }) {
        await dispatch('initialise', true);
        return authService.login();
    },
    async logout ({ dispatch }) {
        window.location.href = '/Home/Logout';
    },
    async getToken ({ commit, dispatch }) {
        if (!authService) await dispatch('initialise', false);
        const token = await (authService.getToken()).catch(() => {
        });
        if (token) {
            commit('setToken', token.accessToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token.accessToken}`
            commit('setLoggedIn', token.idToken);
            commit('setUserLoginInfo', token.account.idTokenClaims || token.idTokenClaims);
            return token;
        }
        return null;
    },

};

const mutations = {
    setLoggedIn (s, token) {
        s.loggedIn = !!token;
    },
    setUser (s, userInfo) {
        s.userInfo = userInfo;
    },
    setToken (s, token) {
        s.token = token;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
