<template>
    <div class="terms-scroll">
        <h2>Assumptions</h2>
        <p>The figures and other information provided on the My Retirement Planner are based on current factors which will be different from actual experience, for example, changes in the cost of living, investment performance, annuity pricing and life expectancy.
            These assumptions should be read in conjunction with the terms of the My Retirement Planner.  All values have been rounded.
        </p>
        <ul>
            <li>Increases in the cost of living of 3% per annum (the FCA’s assumption for RPI inflation).</li>
            <li>If you are currently a contributing member then your projected pension assumes you remain contributing until that time and is based upon your current earnings and part-time hours.</li>
            <li>Within the Cadent Gas Pension Scheme the terms for receiving pension early, converting pension into cash and the terms for the Bridge the Gap option are not guaranteed and are reviewed from time to time.</li>
            <li>To illustrate any potential uplift at your GMP payment age we have assumed the future cost of living to be 3% pa and your GMP uplift (if any) could be higher or lower than the value illustrated.</li>
            <li>Your illustrated transfer value is not guaranteed.  In practice it will vary each month and may go up or down.</li>
            <li>Your illustrative pension with an insurer figure is based upon annuity rates provided by WPSA.  These annuity rates are not guaranteed and do not take into account all of your own personal circumstances.  Your actual figures may be higher or lower than this.</li>
            <li>Any illustrated tax amounts shown are based on current UK tax rules.</li>
        </ul>
        <p>Version {{ version }}</p>
    </div>
</template>
<script>
import ng from './../../calculations/nationalgrid';
export default {
    data () {
        return {
            version: ng.version
        }
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';
.terms-scroll {
        height: 100%;
        overflow: auto;
        padding: 1rem;
    }
    a {
        color: $nationalGridBlue;
        text-decoration: underline;
    }
    h3 {
        margin-top: 1rem;
        color: $highlightColour;
    }
    ul {
        list-style: square;
        padding-left: 1.5rem;
        li {
            line-height: 1.5;
            margin-bottom: 0.25rem;
            color: #333;
        }
    }
    table {
        thead {
            background: $nationalGridBlue;
            color: white;
        }
        td, th {
            padding: 0.5rem;
            line-height: 1.5;
        }

        tbody tr:nth-child(even) {
            background: #f4f4f4;
        }
    }
</style>
