<template>
<modal title="How to seek advice from WPSA" @close="$emit('close')" :shortIntro="true">
  <template slot="content">
      <div class="wpsa">
            <div class="intro">If you wish to register to speak to WPSA, and give the go-ahead for your data to be shared with them, here are the steps to follow:</div>
          <div class="flow-diagram container">
            <div class="row">
                <div class="flow-box"><span>1</span>Read the important notes about advice provided by WPSA</div>
                <div class="flow-box"><span>2</span>Click the button: 'I want to register for advice and consent for my data to be shared with WPSA'</div>
                <div class="flow-box"><span>3</span>Complete registration within WPSA portal and book your initial discussion</div>
                <div class="flow-box"><span>4</span>WPSA's Customer Relationship Manager (CRM) will contact you for your initial call to agree next steps and to arrange your meeting with an adviser</div>
            </div>
            <div class="row">
                <div class="flow-box"><span>5</span>Provide WPSA with the information they need in advance of your adviser meeting. Your CRM will support you with this</div>
                <div class="flow-box"><span>6</span>You meet your adviser and agree your plan</div>
                <div class="flow-box"><span>7</span>Your adviser obtains guaranteed pension figures (if required) from the administration team</div>
                <div class="flow-box"><span>8</span>Your adviser issues your personal Recommendation Report</div>
                <div class="flow-box last"><span>9</span>You make an informed decision</div>
            </div>
          </div>
          <div class="container left">
              <p class="animate">In order for WPSA to advise you they require details of your Cadent Gas Pension Scheme benefits. By clicking below you are also consenting for certain personal data, including your illustrative transfer value and other pension figures, held by the Trustees to be transferred securely to WPSA so that they are ready to advise you. All the data transferred will be treated confidentially, stored securely, and it will only cover the information which the financial adviser might reasonably need in order to provide you with information or advice in relation to your options.  (Further details can be found in their <a href="https://www.wpsadvisory.com/privacy-policy" target="_blank">privacy policy</a>).</p>
              <p class="animate">By clicking this button WPSA will then write to you with further details on the next steps. At this stage you are still not obliged to take advice from them and by pressing this button you will not yet trigger any charge or the start of your one piece of paid for advice (if you are eligible).</p>
          </div>
          <div class="container">
            <button v-if="!alreadySubmitted && !hasSubmittedWpsa" :disabled="loading" class="email-button" @click="requestAdvice">I want to register for advice and consent for my data to be shared with WPSA (click here).</button>
            <div class="loading" v-if="loading" />
            <p class="already-submitted" v-if="alreadySubmitted || hasSubmittedWpsa">You have recently made a request to get advice - your WPSA welcome pack will take up to 7 working days to arrive</p>
            <button v-if="alreadySubmitted || hasSubmittedWpsa" :disabled="loading" @click="gotToWPSA" class="email-button">Go to the WPSA Portal (click here).</button>
        </div>
          <div class="terms container">
              <h3>Important notes about advice from WPSA</h3>
              <h4>How can I be sure I will receive independent financial advice from WPSA?</h4>
              <p>WPSA is independent of the Trustees and Cadent. They are being paid to provide the right advice to assist you in making the right decision for you in connection with your options under the Cadent Gas Pension Scheme. They are not paid commission based on the decision that you make. The Trustees hope you consider taking advantage of the offer of assistance to you, but you are under no obligation to contact WPSA.  Equally, you are not under any obligation to follow the advice recommended by WPSA (but if you wish to transfer see below in “What happens if I disagree with the advice?” for more information.)</p>
              <h4>How much does the advice cost me?</h4>
              <p>The Trustees are funding the initial advice from WPSA for most non-retired members of the Cadent Gas Pension Scheme as they approach retirement. This is available from age 54 for most members. However, this advice is only funded once. WPSA can also offer additional support, for example if your needs are particularly complex (such as complex tax issues to consider or if you want advice on other pensions or existing products). If you choose to take this additional support or any further advice in the future then any additional fees incurred will not be paid for by the Trustees. In such cases, WPSA will explain the additional fees you will be liable to pay and will obtain your agreement to those fees first before any further work is carried out.</p>
              <h4>How long does the advice process take?</h4>
              <p>The process will take as long as it takes for you to be in a position to make an informed decision. That will mean WPSA understanding all your personal circumstances, your objectives, and your needs and wants for retirement.</p>
              <p>How long it takes will largely depend on your own situation but typically the first discussion with your adviser will last around an hour and you should allow a minimum of eight weeks for the full advice process, although it could be longer if you have a complex set of circumstances. WPSA do not limit the time they spend with members and it not unusual for members to need three or four discussions before agreement is reached about the best course of action. It’s a very important discussion so spending the right amount of time is vital.</p>
              <p>Your adviser will want to understand your plans for retirement and your wider financial position, including other pensions and savings, as well as outgoings now and expected in retirement. It is important for you and your adviser that this information is provided in advance of your scheduled meeting. That way you are both prepared. At the point of booking your meeting WPSA will provide you with access to their online portal to provide this information. If you cannot complete this online or in advance of your meeting, please speak with WPSA to agree an alternative course of action.</p>
              <h4>What happens if I want advice from WPSA again?</h4>
              <p>If you have already taken your funded advice from WPSA you may take advantage of their advice again in future but this will usually cost you £875 + VAT (this amount may be reviewed in future). This is a subsidised rate agreed with the Trustees. If your needs are particularly complex, additional amounts may be payable. In such cases, WPSA will explain the additional fees you will be liable to pay and will obtain your agreement to those fees first before any further work is carried out.</p>
              <h4>Can I have face to face advice?</h4>
              <p>Most people prefer to take advice from WPSA on the phone or via an online meeting. However, WPSA can also meet you in person if you prefer but this is subject to their discretion and depends on their resource and availability. </p>
              <h4>What if I live or pay tax overseas?</h4>
              <p>Unfortunately WPSA cannot provide advice to you if you are an overseas resident. However, if you live overseas but are still a UK taxpayer they can usually advise you.</p>
              <h4>Can WPSA advise me on my other pension arrangements?</h4>
              <p>If you are eligible for funded advice, this funding only covers advice on the options you have with the Cadent Gas Pension Scheme, however your adviser in determining what is the right course of action with you will need to understand your wider finances including other pensions schemes. If you would like advice on these as well, you will need to meet any additional fees that are payable. We suggest you discuss this with WPSA. It is important to note that WPSA won’t undertake any additional work without your agreement and prior written consent. Any work you ask them to carry out will be quoted and charged in pounds and pence and not as percentage of your fund value.</p>
              <h4>What if my transfer value is less than £30,000?</h4>
              <p>If your transfer value is less than £30,000 you are able to transfer your benefits out of the Scheme without taking financial advice. Therefore, the Trustees are not funding advice from WPSA in such cases. However, you may still seek support and advice from WPSA but it will cost you £875 + VAT. You may also be eligible for an additional option to receive your entire Scheme benefit as a cash lump sum at retirement.</p>
              <h4>Can I use my own adviser instead?</h4>
              <p>Yes but the Trustees will not pay for this.</p>
              <h4>How are WPSA being monitored?</h4>
              <p>The performance of WPSA will be monitored by the Trustees through feedback requests to members using this service. The Trustees reserve the right to stop paying for advice in future and/or may select an alternative financial adviser.</p>
              <h4>Are my transfer value and other pension figures guaranteed?</h4>
              <p>As previously noted My Retirement Planner is intended for illustrative purposes only and its results do not constitute a promise or guarantee of the benefits you’ll receive. These figures are not guaranteed, will vary each month and may go up or down. If you click the button “I want to explore taking advice, and consent to my data being shared with WPSA” these illustrative figures will be shared with WPSA. Your WPSA Adviser may obtain guaranteed figures at a later stage depending on the options you are interested in considering.</p>
              <h4>What happens if I disagree with the advice?</h4>
              <p>You are under no obligation to follow the advice. However, if the transfer value of your deferred pension exceeds £30,000, you must take appropriate financial advice (and your adviser must produce a certificate for the Trustee to confirm that advice has been given) before you can make a transfer to a DC arrangement. The Trustees cannot pay such a transfer value without sight of a certificate. Please note that if WPSA (or a different financial adviser) considers that a transfer out is not right for you, they may not provide you with a certificate and, as a result, the transfer would not proceed.</p>
              <h4>Where can I find more details about WPSA?</h4>
              <p>Please visit their website at <a href="https://www.wpsadvisory.com/" target="_blank">https://www.wpsadvisory.com</a></p>
          </div>
      </div>
      <div class="mini-modal" v-if="showSubmittedModal">
            <div class="modal-content">
            <p>Thank you for exploring your option.  Please now complete the registration within the WPSA portal and book your initial discussion.  WPSA's Customer Relationship Manager (CRM) will contact you for your initial call to agree next steps and to arrange your meeting with an adviser.</p>
            <p>Please remember:</p>
            <p>The information provided on My Retirement Planner shows your illustrative Scheme retirement figures and an illustrative transfer value, which represents the equivalent cash value of the Scheme benefits you've earned so far. These figures are not guaranteed.</p>
            <p>If, after their comprehensive review, WPSA believe that it is in your best interests to transfer out, they will provide you with a transfer out recommendation (including a Section 48 form), which is required for the transfer to proceed.</p>
            <button class="btn feedback" @click="$store.commit('showFeedback', true)">Give feedback</button>
        </div>
        <div class="modal-footer">
            <button class="btn yes" @click="logout">Log out</button>
            <button class="btn no" @click="showSubmittedModal = false">Continue to explore options</button>
        </div>
    </div>
  </template>
</modal>
</template>

<script>
import { TweenMax, TimelineLite } from "gsap";
import constants from './../constants.js';
import Modal from './common/Modal';
export default {
    name: 'Annuity',
    components: { Modal },
    data () {
        return {
            showQuestions: false,
            mounted: false,
            debounce: 1000,
            loading: false,
            showSubmittedModal: false,
            alreadySubmitted: false
        };
    },
    methods: {
        submit () {
            this.$emit('submit');
        },
        requestAdvice () {
            this.loading = true;
            window.open('https://cadent.wpsregistration.com', '_blank');
            this.$store.dispatch('requestAdvice').then(() => {
                this.loading = false;
                this.showSubmittedModal = true;
                this.$store.dispatch("setValue", { prop: 'submittedWpsa', value: true });
            });
            this.$store.dispatch('postAnalytics', { measure: constants.Link_ExploreAdvice_Clicked, value: new Date() });
        },
        gotToWPSA () {
            window.open('https://cadent.wpsregistration.com', '_blank');
        },
        logout () {
            this.$store.dispatch('logout');
        }
    },
    computed: {
        currentQuestion () {
            if (this.mounted && this.$refs.questions) {
                return this.$refs.questions.questionNo;
            }
            return '';
        },
        hasSubmittedWpsa () {
            return this.$store.getters.getValue('submittedWpsa');
        }
    },

    mounted () {
        this.mounted = true;
        this.$store.dispatch('getCanRequestAdvice').then(response => this.alreadySubmitted = !response.data);
        var timeline = new TimelineLite();
        timeline.add(TweenMax.from(".scheme-pension-container h2", 1, { opacity: 0, y: -5, scaleX: 0.5, scaleY: 0.5 }));
    },
    watch: {
    }
};
</script>


<style scoped lang="scss">
@import './../scss/variables.scss';
@import './../scss/mixins.scss';


@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.wpsa {
    padding: 2rem;
    margin-top: 10rem;
    margin-bottom: 1rem;
}

.flow-diagram {
    .row {
        display: flex;
        @media(max-width: 1100px) {
            flex-direction: column;
        }
    }
    .flow-box {
        background: $nationalGridBlue;
        color: white;
        box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
        margin-right: 2rem;
        margin-bottom: 1rem;
        padding: 1.25rem;
        line-height: 1.5;
        position: relative;
        animation: fadeIn 1s;
        animation-fill-mode: both;
        flex: 1 1 auto;
        span {
            display: inline-block;
            margin-right: 0.5rem;
        }
        &:nth-child(even) {
            background: lighten($nationalGridBlue, 5);
        }
        &:after {
            content: '';
            position: absolute;
            width: 1.7rem;
            height: 2px;
            background: $highlightColour;
            right: -1.7rem;
            top: 50%;
        }
        &:before {
            content: '';
            height: 10px;
            width: 10px;
            top: calc(50% - 5px);
            transform: rotate(45deg);
            border-top: 2px solid $highlightColour;
            border-right: 2px solid $highlightColour;
            position: absolute;
            right: -1.7rem;
        }
        &.last {
            margin-right: 0;
            &:after, &:before {
                display: none;
            }
        }
        @media(max-width: 1100px) {
            margin-bottom: 1.8rem;
            margin-right: 0;
            &:after {
                transform: rotate(90deg);
                right: 50%;
                top: calc(100% + 10px);
            }
            &:before {
                right: calc(50% + 6px);
                top: calc(100% + 10px);
                transform: rotate(135deg);
            }
        }
    }
}

.container.left {
    animation: fadeIn 1s;
    animation-fill-mode: both;
}

.email-button {
    animation: fadeIn 1s;
    animation-fill-mode: both;
    background: $nationalGridPink;
    color: white;
    border: 0;
    display: inline-block;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
    padding: 1rem;
    cursor: pointer;
    &:disabled {
        background: #aaa;
        pointer-events: none;
    }
}

.terms {
    animation: fadeIn 1s 1s;
    animation-fill-mode: both;
    position: relative;
    margin-top: 2rem;
    background: rgba(255, 255, 255, 0.3);
    h3 {
        color: $highlightColour;
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
    h3, h4 {
        text-align: left;
    }
    a {
        color: $nationalGridBlue;
        text-decoration: underline;
    }
}

.mini-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  transform: translateY(-50%) translateX(-50%);
  background: white;
  padding: 2rem;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.7) 0 0 0 100rem;
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 100vmax;
  animation: growIn 0.5s;
  animation-fill-mode: both;
  .btn {
     border: 0;
     background: $nationalGridLightBlue;
     color: white;
     padding: 1rem 2rem;
     margin: 0.5rem;
     font-size: 1.1rem;
         transition: transform 0.5s, box-shadow 0.5s;
     box-shadow: rgba(189, 164, 164, 0.3) 2px 2px 4px;
     &.yes {
         background: $nationalGridGreen;
     }
     &.feedback {
         background: $highlightColour;
         margin-bottom: 2rem;
     }
    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
    }

  }
}

.already-submitted {
    text-align: center;
    background: $nationalGridOrange;
    color: white;
    padding: 1rem;
    font-size: 1rem;
    max-width: 800px;
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
}
</style>
