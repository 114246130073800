<template>
<div class="modal">
  <div class="back" @click="$emit('close')">
    <svg width="20" height="20" viewBox="0 0 20 20">
      <path fill="#fff" d="M0.646 10.146l6-6c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-5.146 5.146h16.293c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-16.293l5.146 5.146c0.195 0.195 0.195 0.512 0 0.707-0.098 0.098-0.226 0.146-0.354 0.146s-0.256-0.049-0.354-0.146l-6-6c-0.195-0.195-0.195-0.512 0-0.707z"></path>
    </svg>
  </div>
  <div class="intro">
    <div class="container">
      <h2>{{ title }}</h2>
      <slot name="intro"></slot>
    </div>
    <div class="white-curve"></div>
  </div>
  <div class="content" style="width: 100%; margin-top: 2rem">
    <div class="grey-curve"></div>
    <slot class="content container" name="content"></slot>
  </div>
</div>

</template>

<script>
export default {
    name: 'Modal',
    props: [ 'title', 'shortIntro' ]
};
</script>


<style scoped lang="scss">
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';

.modal {
    position: fixed;
    top: 2vh;
    left: 2vw;
    z-index: 99;
    width: 96vw;
    bottom: 2vh;
    box-shadow: rgba(0, 0, 0, 0.7) 0 0 0 100rem;
    box-shadow: rgba(0, 0, 0, 0.7) 0 0 0 100vmax;
    background: white;
    animation: dropIn 0.5s;
    @include responsive (mobile) {
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
    }
    overflow: auto;
    min-height: 29vw;
    padding-bottom: 2rem;
    @include responsive (mobile) {
        max-height: 100vh;
    }
    display: flex;
    flex-direction: column;

    font-size: 1.3rem;
}

.content.container {
    padding-top: 2rem;
    @include responsive(mobile) {
        padding-top: 10rem;
    }
}
.content {
    text-align: center;
    flex-grow: 1;
}
.modal {
    .questions {
        margin-bottom: 0;
    }
    p, ul, li{
        padding: 0.25rem;
        text-align: left;
    }
    ul {
    }
    li {
        margin-left: 2.5rem;
    }

    .btn.start {
        background: $nationalGridPink;
        display: inline-block;
        padding: 0.5rem 2rem;
        color: white;
        margin-top: 1rem;
        position: relative;
        border-radius: 3rem;
        &:hover {
            cursor: pointer;
            background: lighten($highlightColour, 5);
        }
    }
}

.intro {
    position: relative;
    @include responsive (mobile) {
        position: fixed;
        z-index: 9;
        width: 100%;
    }
    @include responsive (tablet) {
        height: 130px;
    }
    .amount {
        display: inline-block;
        color: $nationalGridOrange;
    }
    h2 {
        color: $highlightColour;
        font-size: 2rem;
        font-weight: 400;
        padding: 0 0.5rem;
        @include responsive (mobile) {
            padding-left: 2.5rem;
            font-size: 1.5rem;
        }
    }

    p {
        max-width: 850px;
        color: white;
        font-size: 1.34rem;
        padding: 0rem 0.5rem;
        animation: growIn 0.5s;
        @include responsive(tablet) {
            background: transparentize($nationalGridBlue, 0.3);
            width: 60vw;
        }
        @include responsive (mobile) {
            max-width: none;
            background: none;
            width: 100%;
            max-height: 100px;
            overflow: auto;
            &:first-of-type {
                margin-top: 3rem;
            }
        }
    }

    .container {
        position: relative;
        z-index: 1;
        @include responsive (mobile) {
            padding: 1rem 1.5rem;
        }
    }
    padding-bottom: 0;
}

@keyframes growIn {
    from { transform: scale(1.3); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
}

.back {
    position: absolute;
    color: white;
    top: 1rem;
    left: 1.5rem;
    transform: scale(1.75);
    z-index: 2;
    z-index: 11;
    &:hover {
        cursor: pointer;
    }
    @include responsive (mobile) {
        position: fixed;
    }
}

.title {
    font-size: 1.4rem;
    color: #333;
    padding: 0.5rem 1rem;
    position: absolute;
    top: 12rem;
    line-height: 1.5;
    right: 2.5vw;
    width: 400px;
    @include responsive (sd) {
        position: relative;
        right: auto;
        top: 0;
        left: 50%;
        padding: 0 2rem;
        font-size: 1.2rem;
        display: inline-block;
        width: auto;
        transform: translateX(-50%);
    }
    @include responsive (mobile) {
        position: relative;
        right: auto;
        top: 0;
        margin-bottom: 1rem;
    }
}

.white-curve {
    left: 0rem;
    width: 100%;
    height: 15rem;
    position: absolute;
    top: 0;
    z-index: 0;
    background: $nationalGridBlue;
    background-image: url('./../../assets/white-curve.png');
    background-position: bottom center;
    background-size: 150rem 13rem;
    background-repeat: no-repeat;
    box-sizing: content-box;
    transition: height 1s, background-size 1s;
    &.short {
        height: 8rem;
        background-size: 150rem 8rem;
    }
    &:after {
        content: '';
        background: white;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0px;
    }
    @include responsive(mobile) {
        &.short {
            height: 7rem;
            background-size: 100vw 7rem;
        }
        height: 13rem;
        background-size: 100vw 1rem;
    }
}


.grey-curve {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 22rem;
    position: absolute;
    margin-top: 0rem;
    z-index: 0;
    background: white;
    background-image: url('./../../assets/grey-curve-no-pylons.png');
    background-position: bottom center;
    background-size: 200rem 22rem;
    background-repeat: no-repeat;
    box-shadow: #e1e1e1 0 100vh 0 100vh;
}

</style>
