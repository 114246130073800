import Vue from 'vue';
import usersApi from './../../api/user';
import util from './../../lib/util';
import calculations from './../../calculations/nationalgrid';
const state = {
    user: null,
    bridgeTheGap: false,
    retirementAge: 60,
    schemePensionAge: 65,
    schemePensionAmount60: 12000,
    schemePensionCashMaxProp: 0,
    annuityIncreasing: true,
    annuitySpouse: true,
    annuityCashMaxProp: 0,
    annuitySmoker: false,
    annuityChronic: false,
    annuityRefine: false,
    flexibleIncomeCashMaxProp: 0,
    flexibleIncomeTaxedCashMaxProp: 0,
    flexibleIncomeAge: 90,
    loginDetails: {
        id: null,
        text: '',
        username: ''
    },
    loggedIn: false,
    usersList: [],
    noAccount: false,
};

const getters = {
    getNoAccount (state) {
        return state.noAccount;
    },
    getCalculationsTest (state) {
        return {
            retirementAge: state.retirementAge,
            schemeCalc: calculations.pensionFn.getSchemeCalc(state, state.schemePensionCashMaxProp/100, state.bridgeTheGap),
            annuityCalc: calculations.pensionFn.getAnnuityCalc(state, state.schemePensionCashMaxProp/100, state.annuityIncreasing, state.annuitySpouse, state.annuitySmoker, state.annuityChronic),
            drawdownCalc: calculations.pensionFn.getDrawdownCalc(state, state.schemePensionCashMaxProp/100,state.flexibleIncomeTaxedCashMaxProp/100, state.flexibleIncomeAge)
        };
    },
    getCalculations (state) {
        return {
            retirementAge: state.retirementAge,
            schemeCalc: calculations.pensionFn.getSchemeCalc(state, state.schemePensionCashMaxProp/100, state.bridgeTheGap),
            annuityCalc: calculations.pensionFn.getAnnuityCalc(state, state.annuityCashMaxProp/100, state.annuityIncreasing, state.annuitySpouse, state.annuitySmoker, state.annuityChronic),
            drawdownCalc: calculations.pensionFn.getDrawdownCalc(state, state.flexibleIncomeCashMaxProp/100,state.flexibleIncomeTaxedCashMaxProp/100, state.flexibleIncomeAge)
        };
    },
    getSchemeCalc (state) {
        return calculations.pensionFn.getSchemeCalc(state, state.schemePensionCashMaxProp/100, state.bridgeTheGap);
    },
    getAnnuityCalc (state) {
        return calculations.pensionFn.getAnnuityCalc(state, state.annuityCashMaxProp/100, state.annuityIncreasing, state.annuitySpouse, state.annuitySmoker, state.annuityChronic);
    },
    getDrawdownCalc (state) {
        return calculations.pensionFn.getDrawdownCalc(state, state.flexibleIncomeCashMaxProp/100,state.flexibleIncomeTaxedCashMaxProp/100, state.flexibleIncomeAge);
    },
    getUser (state) {
        return state.user;
    },
    getLoginDetails (state) {
        return state.loginDetails;
    },
    getRetirementAge () {
        return state.retirementAge;
    },
    getSchemePensionCashMaxProp () {
        return state.schemePensionCashMaxProp;
    },
    getBridgeTheGap () {
        return state.bridgeTheGap;
    },
    getAnnuityCashMaxProp () {
        return state.annuityCashMaxProp;
    },
    getAnnuitySpouseOption () {
        return state.annuitySpouse;
    },
    getAnnuityIncreasingOption () {
        return state.annuityIncreasing;
    },
    getSmoking () {
        return state.annuitySmoker;
    },
    getChronic () {
        return state.annuityChronic;
    },
    getRefine () {
        return state.annuityRefine;
    },
    getFlexibleIncomeAge () {
        return state.flexibleIncomeAge;
    },
    getFlexibleIncomeMaximumCashProp () {
        return state.flexibleIncomeCashMaxProp;
    },
    getFlexibleIncomeMaximumTaxedCashProp () {
        return state.flexibleIncomeTaxedCashMaxProp;
    },
    getLoggedIn (s) {
        return s.loggedIn;
    },
    getUsersList: s => s.usersList
};

const actions = {
    getUser (context, details) {
        context.commit('clearUser');
        return usersApi.getUser(details.userId, details.schemeId, (user) => {
            if (user === 'NoMember') {
                context.commit('setNoAccount', true);
            } else if (user) {
                user = calculations.initialiseUser(util.parseJson(user));
                context.commit('setUser', user);
                context.commit('setRetirementAge', Math.max(user.earliest_retirement_age, user.ageNow));
                context.commit('setNoAccount', false);
            }
            return user;
            //context.commit('setUser',  user);
        }, () => {
            window.location.href = "/";
        });
    },
    setBridgeTheGap (context, value) {
        context.commit('setBridgeTheGap', value);
    },
    setRetirementAge (context, value) {
        context.commit('setRetirementAge', value);
    },
    setSchemePensionCashMaxProp (context,value) {
        context.commit('schemePensionCashMaxProp', value);
    },
    setAnnuityIncreasing (context, value) {
        context.commit('setAnnuityIncreasing', value);
    },
    setAnnuitySpouse (context, value) {
        context.commit('setAnnuitySpouse', value);
    },
    setAnnuityRefine (context, value) {
        context.commit('setAnnuityRefine', value);
    },
    setAnnuitySmoking (context, value) {
        context.commit('setAnnuitySmoking', value);
    },
    setAnnuityChronic (context, value) {
        context.commit('setAnnuityChronic', value);
    },
    setAnnuityCashMaxProp (context,value) {
        context.commit('setAnnuityCashMaxProp', value);
    },
    setFlexibleIncomeAge (context, value) {
        context.commit('setFlexibleIncomeAge', value);
    },
    setflexibleIncomeCashMaxProp (context, value) {
        context.commit('setFlexibleIncomeCashMaxProp', value);
    },
    setflexibleIncomeTaxedCashMaxProp (context, value) {
        context.commit('setFlexibleIncomeTaxedCashMaxProp', value);
    },
    // login (context, { username, password }) {
    //     return usersApi.login(username, password).then(result => {
    //         context.commit('setUserLoginInfo', {
    //             username,
    //             text: result.data.text,
    //             id: result.data.id
    //         });
    //     });
    // },
    submitSecurityAnswer (context, { username, answer, id }) {
        return usersApi.submitSecurityAnswer(username, answer, id)
            .then(() => {
                context.commit('setLoggedIn', true);
                return true;
            });

    }
};

const mutations = {
    setLoggedIn () {
        state.loggedIn = true;
    },
    setUserLoginInfo (state, info) {
        state.loginDetails = info;
    },
    clearUser (state) {
        state.user = null;
        state.noAccount = false;
    },
    setUser (state, user) {
        Vue.set(state, 'user', user);
        state.retirementAge = user.nra;
    },
    setBridgeTheGap (state, amount) {
        state.bridgeTheGap = amount;
    },
    setRetirementAge (state, amount) {
        state.retirementAge = amount;
    },
    schemePensionCashMaxProp (state, amount) {
        state.schemePensionCashMaxProp = amount;
    },
    setAnnuityIncreasing (state, amount) {
        state.annuityIncreasing = amount;
    },
    setAnnuitySpouse (state, amount) {
        state.annuitySpouse = amount;
    },
    setAnnuityRefine (state, amount) {
        state.annuityRefine = amount;
    },
    setAnnuitySmoking (state, amount) {
        state.annuitySmoker = amount;
    },
    setAnnuityChronic (state, amount) {
        state.annuityChronic = amount;
    },
    setAnnuityCashMaxProp (state, amount) {
        state.annuityCashMaxProp = amount;
    },
    setFlexibleIncomeCashMaxProp (state, amount) {
        state.flexibleIncomeCashMaxProp = amount;
    },
    setFlexibleIncomeTaxedCashMaxProp (state, amount) {
        state.flexibleIncomeTaxedCashMaxProp = amount;
    },
    setFlexibleIncomeAge (state, amount) {
        state.flexibleIncomeAge = amount;
    },
    setUsersList (state, users) {
        state.usersList = users;
    },
    setNoAccount (state, val) {
        state.noAccount = val;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
