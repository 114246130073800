import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import scheme from './modules/scheme';
import factfind from './modules/factfind';
import pageProgress from './modules/page-progress';
import analytics from './modules/analytics';
import print from './modules/print';
import auth from './modules/auth';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        scheme,
        factfind,
        pageProgress,
        analytics,
        print,
        auth
    }
});
