<template>
<div class="final-questions" v-if="getValue('whatShouldIDoNowVisible')">
    <div class="final-white-curve">
    </div>
    <div class="container">
        <h2 class="next-steps">What should I do now?</h2>
    <div id="finalQuestions">
        <p class="pre-question">As you can see, you have a number of different options to consider.  To help you make the right choice to suit your circumstances, the Trustees of the {{ schemeDetails.schemeName }} has arranged for you to have access to an independent financial adviser who will provide you with impartial advice.  The Trustees will cover the cost of this impartial financial advice once <span v-if="user.type == 'age_restricted'"> but normally only after you have reached age 54.</span><span v-if="user.type != 'age_restricted'"> so, to get the most out of this, you should consider if and when you wish to make use of this offer before you retire.</span>
        </p>
        <div v-if="user.type != 'age_restricted'" class="question-title">
            <p>Based on the options you have seen, do you think you are likely to make a decision on your retirement benefits in the next year or so?</p>
            <p class="pre-question">This might include: when to retire, whether to take a lump sum and whether or not to transfer.</p>
            <p class="pre-question">Please note the answer to this question doesn’t commit you to any course of action and your answer will not be shared</p>
        </div>
        <div  v-if="user.type != 'age_restricted'"  class="input-field post-animate">
            <div class="yes-no">
                <div class="option">
                    <input id="retiringInTwoYearsYes" type="radio" name="retiringInTwoYears" :value="true" v-model="retiringInTwoYears"/>
                    <label for="retiringInTwoYearsYes" @click="showNext">Yes</label>
                </div>
                <div class="option">
                    <input id="retiringInTwoYearsNo" type="radio" name="retiringInTwoYears" :value="false" v-model="retiringInTwoYears"/>
                    <label for="retiringInTwoYearsNo" @click="showNext">No</label>
                </div>
            </div>
        </div>
        <div v-if="showEndSection || user.type == 'age_restricted'" ref="summary" id="endSection">
            <div class="row preferred" v-if="user.type == 'age_restricted'">
                <div class="summary left">
                    <p class="animate sub-title">If you would like to seek advice now</p>
                    <div class="content">
                        <div>
                            <div>
                                <p class="animate">
                                    The Trustees have arranged to provide you with access to financial advice from WPS Advisory Ltd (“WPSA”) if you wish to choose them as your adviser.  If you wish to take advice from WPSA before you’re within a year of your earliest Scheme retirement age, you can, but you will need to pay for this advice yourself.  However, the Trustees have agreed a price with WPSA which is cheaper than you would typically get if you met with a financial adviser who didn’t know about the Scheme. For an introduction to WPSA please watch the following video.
                                </p>
                                <div  @click="watchWpsVideo" class="video small" title="Watch video" :class="{ watched: watchedWPSVideo }">
                                    <img src="../../assets/wps-thumbnail.png" />
                                    <span>Introduction to WPSA</span>
                                </div>
                                <p class="animate">
                                    You can seek advice from your own financial adviser if you wish.  However, WPSA are specialists in this area and are familiar with the Scheme’s benefits.  The Trustees have also agreed preferential fee rates with WPSA. Although the Trustees are providing you with access to WPSA they cannot take responsibility for their advice.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p class="reminder">As a reminder if you wish to transfer out you must first take financial advice, either from the WPSA adviser or you may prefer to engage another financial adviser. If you wish to draw your pension directly from the {{schemeDetails.schemeName}} there is no requirement for you to take financial advice but it may be in your interests to do so to help explore all the options available to you.</p>
                            <button class="animate btn fact-find" @click="showWpsa = true">
                                If you feel now might be the right time to take advice from WPSA please click here for further details.
                            </button>
                        </div>
                        <div>
                            <img style="height: 250px;" src="../../assets/adviser.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row preferred" v-if="retiringInTwoYears && user.type != 'age_restricted'">
                <div class="summary left">
                    <p class="animate sub-title">Now might be a good time to seek advice</p>
                    <div class="content">
                        <div>
                            <div>
                                <p class="animate">
                                    The Trustees have arranged to provide you with access to paid-for financial advice from WPS Advisory Ltd (“WPSA”) if you wish to choose them as your adviser.  For an introduction to WPSA please watch the following video.
                                </p>
                                <div  @click="watchWpsVideo" class="video small" title="Watch video" :class="{ watched: watchedWPSVideo }">
                                    <img src="../../assets/wps-thumbnail.png" />
                                    <span>Introduction to WPSA</span>
                                </div>
                                <p class="animate">
                                    You can seek advice from your own financial adviser if you wish.  However, WPSA are specialists in this area and are familiar with the Scheme’s benefits.  The Trustees have also agreed preferential fee rates with WPSA. Although the Trustees are providing you with access to WPSA they cannot take responsibility for their advice.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p class="reminder">As a reminder if you wish to transfer out you must first take financial advice, either from the WPSA adviser or you may prefer to engage another financial adviser. If you wish to draw your pension directly from the {{schemeDetails.schemeName}} there is no requirement for you to take financial advice but it may be in your interests to do so to help explore all the options available to you.</p>
                            <button class="animate btn fact-find" @click="showWpsa = true">
                                If you feel now might be the right time to take advice from WPSA please click here for further details.
                            </button>
                        </div>
                        <div>
                            <img style="height: 250px;" src="../../assets/adviser.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row alsoConsider" v-if="retiringInTwoYears && user.type != 'age_restricted'">
                <div class="summary right">
                    <p class="animate sub-title">Do you need a retirement quote?</p>
                    <div class="content">
                        <div v-if="user.type != 'special_case'">
                            <div >
                                <p class="animate">
                                    The numbers provided in My Retirement Planner are similar to those you get with an illustrative retirement quote.  So if all you need is an indication of your pension you don't need to request anything at this stage.
                                </p>
                                <p class="animate">
                                    However, when you want to retire and start receiving your pension from the Scheme you will need to request a retirement pack to start this process. This should be done at least three months before retiring.
                                    To formally request a retirement quote please contact Mercer using the online contact portal <a href="https://www.contact.mercer.com" target="_blank">www.contact.mercer.com</a> or by telephone (0345 528 0601) with your planned retirement date together with your scheme reference number and/or date of birth.
                                </p>
                            </div>
                        </div>
                        <div v-if="user.type == 'special_case'">
                            <div >
                                <p class="animate">
                                    When you want to retire and start receiving your pension from the Scheme you will need to request a retirement pack to start this process. This should be done at least three months before retiring.
                                    To formally request a retirement quote please contact Mercer using the online contact portal <a href="https://www.contact.mercer.com" target="_blank">www.contact.mercer.com</a> or by telephone (0345 528 0601) with your planned retirement date together with your scheme reference number and/or date of birth.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img style="height: 150px;" src="../../assets/retirementquote.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row alsoConsider" v-if="user.type === 'age_restricted'">
                <div class="summary right">
                    <p class="animate sub-title">Do you need a retirement quote?</p>
                    <div class="content">
                        <div >
                            <p class="animate">
                                If you want to know the details of your Scheme pension, you can view this on My Online Pension via the Scheme website. From here you can see your current benefit statement at: Documents / My Personal Documents
                            </p>
                        </div>
                        <div>
                            <img style="height: 150px;" src="../../assets/retirementquote.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row alsoConsider" v-if="retiringInTwoYears && user.type != 'special_case' && user.type != 'age_restricted'">
                <div class="summary left">
                    <p class="animate sub-title">If you're not quite ready to make a decision..</p>
                    <div class="content">
                        <div>
                            <div>
                                <p class="animate">
                                    You can monitor your retirement options and consider your retirement needs periodically.
                                </p>
                                <p class="animate">
                                    The options you have may change over time so we recommend visiting this planner each year so you can consider when you may want to retire.
                                </p>

                            </div>
                        </div>
                        <div>
                            <p class="reminder">&nbsp;</p>
                        </div>
                        <div>
                            <img style="height: 150px;" src="../../assets/monitor.png"/>
                        </div>
                        <div class="spacer" />
                    </div>
                </div>
            </div>
            <div class="row preferred" v-if="!retiringInTwoYears && user.type != 'special_case' && user.type != 'age_restricted'">
                <div class="summary left">
                    <p class="animate sub-title">If you're not quite ready to make a decision..</p>
                    <div class="content">
                        <div>
                            <div>
                                <p class="animate">
                                    You can monitor your retirement options and consider your retirement needs periodically.
                                </p>
                                <p class="animate">
                                    The options you have may change over time so we recommend visiting this planner each year so you can consider when you may want to retire.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p class="reminder">&nbsp;</p>
                        </div>
                        <div>
                            <img style="height: 150px;" src="../../assets/monitor.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row alsoConsider" v-if="!retiringInTwoYears && user.type != 'age_restricted'">
                <div class="summary right">
                    <p class="animate sub-title">If you're still unsure you can get help and advice</p>
                    <div class="content">
                        <div style="width: 30%">
                            <p class="reminder">Remember, the Trustees will only pay for you to receive this advice once and since you are not considering making a decision on your retirement for the time being most people in this situation do not take advice now.
                              </p>
                            <p class="reminder">Also if you wish to transfer out you must first take financial advice, either from the WPSA adviser or you may prefer to engage another financial adviser. If you wish to draw your pension directly from the {{schemeDetails.schemeName}} there is no requirement for you to take financial advice but it may be in your interests to do so to help explore all the options available to you.</p>
                            <button class="animate btn fact-find" @click="showWpsa = true">
                                If you feel now might be the right time to take advice from WPSA please click here for further details.
                            </button>
                        </div>
                        <div style="width: 70%; padding: 0 4rem 0 0;">
                            <div>
                                <p class="animate">
                                    Most people who aren't considering retirement decide to keep their benefits in the {{schemeDetails.schemeName}} (or at least for the time being).
                                </p>
                                <p>
                                    However, to help members make the right choice at retirement, the Trustees have arranged to provide you with access to paid-for financial advice from WPS Advisory Ltd (“WPSA”) if you wish to choose them as your adviser. For an introduction to WPSA please watch the following video
                                </p>
                                <div  @click="watchWpsVideo" class="video small" title="Watch video" :class="{ watched: watchedWPSVideo }">
                                    <img src="../../assets/wps-thumbnail.png" />
                                    <span>Introduction to WPSA</span>
                                </div>
                                <p class="animate">
                                    You can seek advice from your own financial adviser if you wish.  However, WPSA are specialists in this area and are familiar with the Scheme’s benefits.  The Trustees have also agreed preferential fee rates with WPSA.  Although the Trustees are providing you with access to WPSA they cannot take responsibility for their advice.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img style="height: 250px;" src="../../assets/adviser.png"/>
                        </div>
                        <div class="spacer" />
                    </div>
                </div>
            </div>
            <p class="animate logouttext">To logout, see more information or to provide feedback see the menu bar in the top right-hand corner.</p>
        </div>
        </div>
        <wpsa @close="showWpsa = false" v-if="showWpsa" />
    </div>
</div>
</template>
<script>
import constants from './../../constants.js';
import wpsa from './../wpsa';
import { TweenMax, TimelineLite } from "gsap";
import "gsap/ScrollToPlugin";
export default {
    name: 'final-questions',
    props: {
    },
    components: {
        wpsa
    },
    data () {
        return {
            watchedWPSVideo: false,
            showWpsa: false
        };
    },
    methods: {
        watchWpsVideo () {
            this.watchedWPSVideo = true;
            this.$emit('watchVideo', 'WPS');
        },
        emailClicked () {
            this.$emit('emailClicked');
        },
        getValue (prop) {
            return this.$store.getters.getValue(prop);
        },
        setValue (prop, value) {
            this.$store.dispatch("setValue", { prop, value });
        },
        showNext () {
            this.$nextTick(() => {
                if (this.retiringInTwoYears) {
                    this.$store.dispatch('postAnalytics', { measure: constants.Button_MakeChoiceYes, value: new Date() });
                } else {
                    this.$store.dispatch('postAnalytics', { measure: constants.Button_MakeChoiceNo, value: new Date() });
                }
            });
            this.showEndSection = true;
            window.setTimeout(() => {
                this.scrollDown();
            }, 200);
        },
        scrollDown () {
            this.timeline = new TimelineLite({
                onComplete: () => {
                    this.animationPlaying = false;
                }
            });
            this.timeline.add(
                TweenMax.to(document.getElementById("app"), 0.7, {
                    scrollTo: { y: "#endSection", offsetY: 80 }
                }));
        },
    },
    computed: {
        user () {
            return this.$store.getters.getUser;
        },
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        },
        retiringInTwoYears: {
            get () {
                return this.getValue('retiringInTwoYears');
            },
            set (val) {
                this.setValue('retiringInTwoYears', val);
            }
        },
        showEndSection: {
            get () {
                return this.getValue('showEndSection');
            },
            set (val) {
                this.setValue('showEndSection', val);
            }
        }
    },
    watch: {
        showWpsa () {
            this.$emit('wpsaModalToggled', this.showWpsa);
        }
    }
};
</script>
<style lang="scss" scoped>
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';

#finalQuestions {
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    font-size: 1.4rem;
}

.container {
  @include responsive(tablet) {
    padding: 1rem 2rem 1rem 2rem;
  }
}

h3 {
    margin-top: 2rem;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    color: #333;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    &.four-options {
        max-width: 1400px;
    }
}

.log-out {
    font-size: 1rem;
    margin-top: 3rem;
}

h2 {
    font-size: 1.4rem;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    color: #333;
    &.options-you-have {
        margin-top: 47vh;
    }
    &.next-steps {
        position: relative;
        z-index: 99;
    }
}

 .video {
    margin: 2rem 0px;
    position: relative;
    text-align: center;
    transition: transform 0.5s;
    width: auto;
    &.small {
        background: #e4e4e4;
        text-align: left;
        display: flex;
        align-items: center;
        &.secondary {
            background: transparentize($nationalGridGreen, 0.85);
        }
        &.tertiary {
            background: transparentize($nationalGridOrange, 0.85)
        }
        img {
            vertical-align: middle;
            width: auto;
            height: 50px;
        }
        span {
            vertical-align: middle;
            display: inline-block;
            padding-left: 1rem;
        }
    }
    @include responsive(tablet) {
        // max-width: 260px;
    }
    img {
        max-width: 600px;
        width: 100%;
    }
    &.watched:after {
        background-color: transparentize($nationalGridGreen, 0.1);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        content: 'You have watched this video - click to watch again';
        color: #fff;
        text-align: center;
        font-size: 1rem;
        padding: 0.5rem;
        height: calc(100% - 1rem);
        width: calc(100% - 1rem);
        right: 0;
        position: absolute;
        bottom: 0;

    }
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
}

.preferred {
    padding: 40px;
    background: #ccc;
    margin-top: 30px;
    overflow: hidden;
}

.alsoConsider {
    padding: 40px;
    @include responsive(mobile) {
        padding: 1rem;
    }
}

.spacer {
    float: none !important;
    clear: both !important
}

.summary {
    .reminder{
        font-style: italic;
        font-size: 1.2rem;
    }
    &.left{
        .sub-title{
            text-align: left;
        }
        .content{
            > div{
                float: left;
                &:nth-child(1) {
                    text-align: left;
                    width: 50%;
                    @include responsive(mobile) {
                        width: 100% !important;
                    }

                }

                &:nth-child(2) {
                    text-align: left;
                    padding: 0 80px;
                    width: 30%;
                    @include responsive(mobile) {
                        width: 100% !important;
                        padding: 1rem !important;
                    }
                    .btn{
                        margin-top: 50px;
                    }
                    .reminder{
                        font-style: italic;
                        font-size: 1.2rem;
                    }
                    .info {
                        margin-top: 2rem;
                        color: $nationalGridLightBlue;
                    }
                }
                &:nth-child(3) {
                    text-align: center;
                    width: 20%;
                    @include responsive(mobile) {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    &.right {
        a {
            color: $nationalGridBlue;
        }
        .sub-title{
            text-align: left;
            margin-bottom: 2rem;
        }
        .content{
            overflow: hidden;
            @include responsive(mobile) {
                display: flex;
                flex-wrap: wrap;
            }
            >div{
                float: right;
                &:nth-child(3) {
                    text-align: left;
                    width: 20%;
                    @include responsive(mobile) {
                        width: 100% !important;
                        order: 2
                    }
                }
                &:nth-child(2) {
                    text-align: left;
                    padding: 0 80px;
                    width: 40%;
                    @include responsive(mobile) {
                        width: 100% !important;
                        padding: 1rem !important;
                        order: 1
                    }
                    .btn{
                        margin-top: 50px;
                    }
                    .reminder{
                        font-style: italic;
                        font-size: 1.2rem;
                    }
                    .info {
                        margin-top: 2rem;
                        color: $nationalGridLightBlue;
                    }
                }
                &:nth-child(1) {
                    text-align: left;
                    width: 60%;
                    @include responsive(mobile) {
                        width: 100% !important;
                        order: 3;
                    }
                }
            }
        }
    }

    .sub-title{
        font-size: 1.4rem;
        font-weight: bold;
    }
}

.logouttext{
    text-align: center;
}

.btn.fact-find {
    background: $highlightColour;
    padding: 0.5rem;
    font-size: 1.1rem;
    margin-top: 1rem;
}

.btn.pack {
    width: auto;
    padding: 0.25rem;
    font-size: 1.1rem;
    font-weight: 700;
    z-index: 2;
    background: $nationalGridGreen;
    position: relative;
}

.btn.feedback {
    background: $nationalGridLightBlue;
    font-size: 1.1rem;
    margin-top: 1rem;
    padding: 0.5rem 2rem;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

.btn {
    background: $nationalGridOrange;
    color: white;
    padding: 1rem 0.5rem;
    margin-top: 0.5rem;
    border: 0;
    display: inline-block;
    width: 100%;
    text-align: center;
    line-height: 1.5;
    font-size: 1rem;
    transition: transform 0.5s, box-shadow 0.5s;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px;
    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
    }

    &.small {
        width: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 2rem;
        padding: 1rem 2rem;
        margin-left: auto;
        margin-right: auto;
        color: #fff;
        background: $nationalGridGreen;
        text-shadow: rgba(0, 0, 0, 0.5) 0 0 8px;
        transform-origin: 0% 50%;
        font-size: 1.1rem;
        &:hover {
            transform: translateY(-2px) translateX(-50%);
            box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
        }
        &.greyed {
            box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 4px;
            background: #aaa;
        }
        &.introvideo {
            left: 0;
            transform: none;
            margin-top: 2rem;
        }
    }
    &.yes {
        background: #aaa;
    }
}


.question-title {
    color: $nationalGridBlue;
    text-align: center;
    font-size: 1.4rem;
    margin-top: 3rem;
    font-weight: 300;
    line-height: 1.5;
    .pre-question {
        font-size: 1.2rem;
    }
}
.final-questions {
    background: white;
    margin-top: 9vw;
    padding-bottom: 2rem;
    position: relative;
    .final-white-curve {
        left: 0rem;
        width: 100%;
        height: 10vw;
        position: absolute;
        margin-top: -9vw;
        z-index: 0;
        background: #e1e1e1;
        background-image: url('./../../assets/white-curve.png');
        background-position: bottom center;
        background-size: 100vw 10vw;
        background-repeat: no-repeat;
        box-sizing: content-box;
        &:after {
            content: '';
            background: white;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0px;
        }
    }
    .card{
        h4{
            color: white;
        }
        background: #ccc;
        box-shadow: none;
    }
}

.seek-advice {
    margin: 1rem;
    .sub-title {
        font-size: 1.4rem;
    }
}
</style>
