/* eslint-disable no-undef */
export const APPLICATIONID = process.env.NODE_ENV === 'production' ? '3633da75-917a-4bde-8d5f-6174efcd402c' : '07741a2e-697a-4c3b-b6eb-8d0ec9aba164';
export const RESPONSETYPE = 'token id_token';
export const REDIRECTURI = process.env.NODE_ENV === 'production' ? 'https://mrp-cadent.lcp.uk.com/planner' : 'http://localhost:8080/planner';
export const TENANTNAME = 'lcpauth.onmicrosoft.com';
export const SIGNINSIGNUPPOLICYNAME = 'B2C_1_SiUpIn_MRP_NG';
export const HELLOJSLOGINNAME = 'b2clogin';
export const EDITPROFILEPOLICYNAME = 'B2C_1_B2C_ProfE';
export const HELLOJSEDITPROFILEPOLICY = 'adB2CEditProfile';
export const SCOPE =  process.env.NODE_ENV === 'production' ? 'https://LcpAuth.onmicrosoft.com/mrp-cadent/admin.access' : 'https://LcpAuth.onmicrosoft.com/mrp-ng-localhost/admin.access';
