<template>
<div class="timeline" :style="{ height: (((user.servicePeriods.length - 1) * 30) + 80) + 'vh' }">
    <div class="container">
        <div class="line straight" />
        <div class="start-age-wrapper" v-for="(service, key) in user.servicePeriods"
            :class="['period-' + key]" :key="'a' + key">
            <div class="circle start-age" :style="{top: 'calc(' + (key * 30 + 30) + 'vh - 11rem)'}">
                <div class="age">Age</div>
                <div class="number"> {{ service.ageJoined | roundDown(1)}}</div>
            </div>
            <p class="start-age" :style="{ top: 'calc(' + (key * 30 + 30) + 'vh - 12rem)' }">
                You joined the Scheme {{ user.servicePeriods.length > 1 ? '(' + service.periodName + ')' : '' }}
            </p>
            <div class="svg-container joined-age" :style="{top: 'calc(' + (key * 30 + 30) + 'vh - 6rem)'}">
                <svg width="100%" height="100%" viewBox="0 0 180 150" preserveAspectRatio="none">
                    <path d="M10 150 C 40 40, 65 60, 95 80 S 150 80, 180 0"  class="light" vector-effect="non-scaling-stroke" />
                    <path d="M10 150 C 40 45, 65 65, 95 85 S 150 85, 180 0" vector-effect="non-scaling-stroke" />
                </svg>
            </div>
        </div>
        <div class="left-age-wrapper" v-for="(service, key) in user.servicePeriods" :class="['period-' + key]" :key="'b' + key">
            <p class="left-age" :style="{ top: 'calc(' + (key * 30 + 30) + 'vh - 12rem + 22vh)' }">
                {{ user.status === 'active'? (user.canRetireFrom > user.ageNow? 'Today you\'re looking at your retirement options' :'You\'ve been able to take your benefits from') : 'You left the Scheme'}}
            </p>
            <div class="circle left-age" :style="{top: 'calc(' + (key * 30 + 30) + 'vh - 13rem + 22vh)'}">
                <div class="age">Age</div>
                <div class="number">{{ user.status === 'active'? (user.canRetireFrom > user.ageNow? user.ageNow : user.canRetireFrom) : service.ageLeft  | roundDown(1) }}</div>
            </div>
            <div class="svg-container left-age" v-if="key === user.servicePeriods.length-1" :style="{ top: 'calc(' + (key * 30 + 30) + 'vh - 7rem + 22vh)' }">
                <svg width="100%" height="100%" viewBox="0 0 180 150" preserveAspectRatio="none" class="left-to-retirement">
                    <path d="M10 150 C 40 40, 65 60, 95 80 S 150 80, 180 0" class="light" vector-effect="non-scaling-stroke" />
                    <path d="M10 150 C 40 45, 65 65, 95 85 S 150 85, 180 0" vector-effect="non-scaling-stroke" />
                </svg>
            </div>
        </div>
        <div class="retirement-age-wrapper">
            <div class="circle retired-age" :style="{ top: 'calc(' + ((user.servicePeriods.length - 1) * 30 + 45) + 'vh - 7rem + 22vh)' }">
                <div class="age">Age</div>
                <div class="number">{{  user.status === 'active' && user.canRetireFrom > user.ageNow?  user.canRetireFrom : user.ageNow | roundDown(1) }}</div>
            </div>
            <p class="retired-age" :style="{ top: 'calc(' + ((user.servicePeriods.length - 1) * 30 + 42) + 'vh - 7rem + 22vh)' }">
                {{ user.status === 'active' && user.canRetireFrom > user.ageNow? 'You can take your benefits from' : 'Today we can look at your retirement income options'}}
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'timeline',
    props: {
    },
    data () {
        return {
        };
    },
    computed: {
        user () {
            return this.$store.getters.getUser;
        },
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        }
    }
};
</script>

<style lang="scss" scoped>
@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';
.container {
  @include responsive(tablet) {
    padding: 1rem 2rem 1rem 2rem;
  }
}
.timeline {
    height: 70vh;
    position: relative;
    z-index: 1;
    top: 10vh;
    @include responsive(mobile) {
        top: 40vh;
        margin-bottom: 30vh;
    }
    p {
        background: rgba(255, 255, 255, 0.8);
        color: $nationalGridBlue;
        font-size: 1.3rem;
        padding: 0.5rem;
        position: absolute;
        width: 13rem;
        @include responsive(mobile) {
            font-size: 0.9rem;
        }
        &.start-age {
            right: calc(15vw + 6rem);
            top: calc(30vh - 12rem);
        }

        &.left-age {
            right: calc(45vw + 6rem);
            top: calc(30vh - 13rem + 22vh);
            @include responsive(mobile) {
                right: calc(45vw + 5.5rem);
                top: calc(30vh - 12rem + 22vh);
                width: 4rem;
            }
        }

        &.left-amount {
            right: calc(45vw - 20rem);
            top: calc(30vh - 8rem + 22vh);
            @include responsive(mobile) {
                right: calc(45vw - 10.5rem);
                top: calc(30vh - 6.5rem + 22vh);
            }
        }
        &.retired-age {
            right: calc(75vw - 18rem);
            top: calc(51vh - 10rem + 22vh);
            @include responsive(mobile) {
                right: calc(75vw - 15rem);
            }
        }
    }
}
.circle {
    border-radius: 100%;
    height: 6rem;
    position: absolute;
    width: 6rem;
    border: 0.35rem solid $cadentBlue;
    z-index: 1;
    .age, .number {
        color: white;
        margin-top: 1rem;
        text-align: center;
    }
    .number {
        font-size: 2rem;
        margin-top: 0.25rem;
    }
    &.start {
        height: 4rem;
        right: 14.9vw;
        top: -17.3rem;
        width: 4rem;
    }
    &.start-age {
        background: $cadentBlue;
        right: calc(15vw - 1rem);
        top: calc(30vh - 11rem);
        &:before {
            background: $pipeYellow;
            box-shadow: inset rgba(0, 0, 0, 0.1) 2px 2px 6px;
            height: 0.6rem;
            left: calc(50% - 0.4rem);
            position: absolute;
            top: -0.9rem;
            width: 1.5rem;
        }
        &:after {
            background: $pipeYellow;
            box-shadow: inset rgba(0, 0, 0, 0.1) 2px 2px 6px;
            height: 0.6rem;
            left: calc(50% - 2.5rem);
            position: absolute;
            top: 5.08rem;
            transform: rotate(40deg);
            width: 1.5rem;
        }
    }
    &.left-age {
        background: $cadentBlue;
        right: calc(45vw - 1.25rem);
        top: calc(30vh - 11.5rem + 22vh);
        &:before {
            background: $pipeYellow;
            box-shadow: inset rgba(0, 0, 0, 0.1) 2px 2px 6px;
            height: 0.6rem;
            left: calc(50% + 1.6rem);
            position: absolute;
            top: 0.3rem;
            transform: rotate(45deg);
            width: 1.5rem;
        }
        &:after {
            background: $pipeYellow;
            box-shadow: inset rgba(0, 0, 0, 0.1) 2px 2px 6px;
            height: 0.6rem;
            left: calc(50% - 2.2rem);
            position: absolute;
            top: 5.25rem;
            transform: rotate(27deg);
            width: 1.5rem;
        }
    }
    &.retired-age {
        background: $cadentBlue;
        right: calc(75vw - 1rem);
        top: calc(51vh - 11rem + 22vh);
        z-index: 3;
        &:before {
            background: $pipeYellow;
            box-shadow: inset rgba(0, 0, 0, 0.1) 2px 2px 6px;
            height: 0.6rem;
            left: calc(50% + 1rem);
            position: absolute;
            top: -0.37rem;
            transform: rotate(35deg);
            width: 1.5rem;
        }
        &:after {
            background: $pipeYellow;
            box-shadow: inset rgba(0, 0, 0, 0.1) 2px 2px 6px;
            height: 0.6rem;
            left: calc(50% - 0.5rem);
            position: absolute;
            top: 5.55rem;
            transform: rotate(0deg);
            width: 1.5rem;
        }
    }
    &.final {
        height: 3rem;
        right: calc(75vw + 0.35rem);
        top: calc(3vh - 5rem + 20vh);
        width: 3rem;
        z-index: 1;
    }
}
.line {
    position: absolute;
    &.straight {
        background: $lightPipeYellow;
        bottom: calc(-30vh + 13rem);
        height: calc(186vh + 11rem);
        right: calc(15vw + 1.65rem);
        width: 0.35rem;
        z-index: 2;
        @include responsive(mobile) {
            display: none;
        }
        &:after {
            background: $pipeYellow;
            content: '';
            height: 100%;
            left: 0.35rem;
            position: absolute;
            top: 0;
            width: 0.35rem;
        }
    }
}
.svg-container {
    height: 0;
    height: 15vh;
    position: absolute;
    right: calc(15vw + 3rem);
    text-align: right;
    top: calc(30vh - 6rem);
    transform-origin: top right;
    width: 30vw;
    &.left-age {
        right: calc(45vw + 3rem);
        top: calc(52vh - 6rem);
    }
    svg {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        transform-origin: top right;
        width: 100%;
        &.left-to-retirement {
            height: 110%;
            top: -4%;
        }
        path {
            fill: transparent;
            position: absolute;
            right: 0;
            stroke-width: 0.35rem;
            stroke: $pipeYellow;
            top: 0;
            transform-origin: top right;
            &.light {
                stroke: $lightPipeYellow;
            }
        }
    }
}
</style>
