export default {
    Overall_Site_Time: 1,
    Overall_Site_Opened: 39,
    Section_Intro_Time: 2,
    Section_Timeline_Time: 3,
    Section_Options_Time: 4,
    Section_WhatMeansForMe_Time: 40,
    Section_WhatShouldIDoNow_Time: 5,
    Video_Intro_Opened: 6,
    Video_Intro_Watched: 7,
    Video_Intro_Time: 8,
    Video_WhenCanIReceive_Opened: 9,
    Video_WhenCanIReceive_Watched: 10,
    Video_WhenCanIReceive_Time: 11,
    Video_OtherOptions_Opened: 12,
    Video_OtherOptions_Watched: 13,
    Video_OtherOptions_Time: 14,
    Video_TransferOptions_Opened: 15,
    Video_TransferOptions_Watched: 16,
    Video_TransferOptions_Time: 17,
    Video_PensionFromInsurer_Opened: 18,
    Video_PensionFromInsurer_Watched: 19,
    Video_PensionFromInsurer_Time: 20,
    Video_FlexibleIncome_Opened: 21,
    Video_FlexibleIncome_Watched: 22,
    Video_FlexibleIncome_Time: 23,
    Video_IntroToWPSA_Opened: 24,
    Video_IntroToWPSA_Watched: 25,
    Video_IntroToWPSA_Time: 26,
    Modal_SchemePension_Opened: 27,
    Modal_SchemePension_Progress: 28,
    Modal_SchemePension_Time: 29,
    Modal_PensionFromInsurer_Opened: 30,
    Modal_PensionFromInsurer_Progress: 31,
    Modal_PensionFromInsurer_Time: 32,
    Modal_FlexibleIncome_Opened: 33,
    Modal_FlexibleIncome_Progress: 34,
    Modal_FlexibleIncome_Time: 35,
    Modal_WPSA_Opened: 36,
    Modal_WPSA_Time: 37,
    Link_ExploreAdvice_Clicked: 38,
    Link_EstimateEmailClicked: 41,
    Link_Printed: 42,
    Section_Intro_Opened: 43,
    Section_Timeline_Opened: 44,
    Section_Options_Opened: 45,
    Section_WhatMeansForMe_Opened: 46,
    Section_WhatShouldIDoNow_Opened: 47,
    Button_MakeChoiceYes: 62,
    Button_MakeChoiceNo: 63,
    SiteFeedback: 56
};
