<template>
<div>
    <div class="no-account" v-if="noAccount">
        <p>You do not appear to have access to the Cadent Gas Pension Scheme My Retirement Planner.  If you are a member of the Cadent Gas Pension Scheme and have not yet started to receive your pension and wish to visit My Retirement Planner please contact your pensions administrator.  For technical support please email <a href="mailto:mrphelpdesk@lcp.uk.com" target="_blank">mrphelpdesk@lcp.uk.com</a>.</p></div>
    <div class="loading" v-if="(!user || !user.age) && !noAccount"></div>
    <div class="loaded" v-else-if="!noAccount" :class="{ 'no-scroll': selectedOption.length }">
        <div class="modal-backdrop" v-if="getValue('areYouSure') && !getValue('whatThisMeansForMeVisible') || video" />
        <!-- <div class="skip" v-if="animationPlaying && getValue('showIntro')" @click="timeline.timeScale(30)">Skip</div>
        <div class="skip" v-if="!getValue('showIntro') && introVideoWatched" @click="watchedIntroVideo">Skip intro</div> -->

        <div class="scroll-holder" v-if="canScrollDown" @click="scrollDown">
        <div class="scroll-down" />
        </div>
        <!-- main page content -->
        <intro-video @watchVideo="watchVideo" @skipVideo="setValue('areYouSureIntro', true)" @continue="watchedIntroVideo" />
        <intro v-show="getValue('showIntro')" />
        <timeline v-show="getValue('showIntro')" />
        <options v-show="getValue('showIntro')" @watchVideo="watchVideo" @showWhatDoIDoNow="showWhatDoIDoNow" @showMeansForMe="showMeansForMe" :whatShouldIDoNowVisible="whatShouldIDoNowVisible" />
        <final-questions @watchVideo="watchVideo" @wpsaModalToggled="wpsaModalToggled" @emailClicked="emailClicked"/>
        <!-- modals -->
        <annuity
            class="modal"
            id="annuity"
            v-if="selectedOption === 'Pension from an insurer'"
            ref="annuityModal"
            @close="selectedOption = ''"
            @submit="optionComplete"/>
        <scheme-pension
            class="modal"
            id="schemePension"
            v-if="selectedOption === 'Scheme Pension'"
            ref="schemePensionModal"
            @close="selectedOption = ''"
            @submit="optionComplete"/>
        <drawdown-temp
            class="modal"
            id="drawdown"
            v-if="selectedOption === 'Flexible Income'"
            ref="drawdownModal"
            @close="selectedOption = ''"
            @submit="optionComplete"/>
        <cash
            class="modal"
            id="cash"
            v-if="selectedOption === 'Cash'"
            @close="selectedOption = ''"
            @submit="optionComplete"/>
        <fact-find
            id="factFind"
            v-if="getValue('factFindVisible')"
            @close="setValue('factFindVisible', false)"
            @submit="setValue('factFindVisible', false)"/>
        <div class="mini-modal" v-if="getValue('areYouSure') && !getValue('whatThisMeansForMeVisible')">
            <div class="give-feedback" @click="showFeedback">Give feedback</div>
            <p>We recommend watching all of the videos above which explain your options before continuing.</p>
            <div class="btn no" @click="setValue('areYouSure', false)">Return to videos</div>
            <div class="btn yes" @click="fullAccess? setValue('whatThisMeansForMeVisible', true) : setSure()">Continue anyway</div>
        </div>
        <div class="mini-modal" v-if="getValue('feedbackExtra')">
            <p>We would love to hear your thoughts regarding your experience on this site.</p>
            <div class="btn no" @click="showFeedback">Give feedback</div>
            <div class="btn yes" @click="setValue('feedbackExtra', false)">Cancel</div>
        </div>
        <div class="mini-modal" v-if="getValue('areYouSureWhatNow') && !getValue('whatShouldIDoNowVisible')">
            <div class="give-feedback" @click="showFeedback">Give feedback</div>
            <p>We recommend understanding and customising your options above before continuing.</p>
            <div class="btn no" @click="setValue('areYouSureWhatNow', false)">Return to my options</div>
            <div class="btn yes" @click="setValue('whatShouldIDoNowVisible', true)">Continue anyway</div>
        </div>
        <div class="mini-modal" v-if="getValue('areYouSureIntro') && !getValue('showIntro')">
            <div class="give-feedback" @click="showFeedback">Give feedback</div>
            <p>We recommend watching the introduction video before continuing.</p>
            <div class="btn no" @click="setValue('areYouSureIntro', false)">Return to the video</div>
            <div class="btn yes" @click="watchedIntroVideo">Continue anyway</div>
        </div>
        <div class="mini-modal mobile-recommended" v-if="!mobileRecommendedDismissed">
            <p>We recommend viewing this site on a tablet, laptop or desktop computer for the best experience.</p>
            <div class="btn no" @click="mobileRecommendedDismissed = true">Ok</div>
        </div>
        <div id="video" v-if="video">
            <div class="video-title">{{ video.split('-')[0] }}</div>
            <div class="close" @click="closeVideo">X</div>
            <div class="flex" :class="{ 'has-playlist': video.toLowerCase().indexOf('flexible') > -1
                || video.indexOf('When can I receive my pension?') > -1
                || video === 'Other options within the scheme'
                || video.indexOf('insurer') > -1}">
                <captions v-if="showCaptions" :video="video" />
                <video v-show="!showCaptions" ref="video" controls="controls" autoplay="autoplay" @ended="videoEnded">
                    <source v-if="video === 'Intro video'" src="https://storemrpngassets.blob.core.windows.net/videos/Cadent%20-%201%20-%20Transfer%20Values.mp4">
                    <source v-if="video.indexOf('When can I receive my pension?') > -1" src="https://storemrpngassets.blob.core.windows.net/videos/Cadent%20-%202%20-%20When%20can%20I%20receive%20my%20NG%20Pension%20Scheme.mp4">
                    <source v-if="video === 'Other options within the scheme'" src="https://storemrpngassets.blob.core.windows.net/videos/Cadent%20-%203%20-%20What%20other%20options%20do%20I%20have%20within%20the.mp4">
                    <source v-if="video.indexOf('Intro to transfer options') > -1" src="https://storemrpngassets.blob.core.windows.net/videos/Cadent%20-%204%20-%20Intro%20to%20transfer%20option.mp4">
                    <source v-if="video === 'Pension from an insurer'" src="https://storemrpngassets.blob.core.windows.net/videos/Cadent%20-%205%20-%20Buying%20a%20pension%20from%20an%20insurer.mp4">
                    <source v-if="video === 'Flexible Income'" src="https://storemrpngassets.blob.core.windows.net/videos/Cadent%20-%206%20-%20Taking%20flexible%20income.mp4">
                    <source v-if="video === 'WPS'" src="https://storemrpngassets.blob.core.windows.net/videos/Cadent%20-%207%20-%20WPS%20Advisory%20Limited%20Version%202.mp4">
                </video>
                <div @click="toggleCaptions" class="caption-toggle">
                    <div class="option" :class="{ selected: !showCaptions }">
                        <img src="../assets/film.svg" width="40" height="40" title="View the video"/> Watch video
                    </div>
                    <div class="option" :class="{ selected: showCaptions }">
                        <img src="../assets/cc.svg" width="40" height="40" title="View the text summary of video"/> View script
                    </div>
                </div>
                <div class="playlist" v-if="video.indexOf('Intro to transfer options') > -1
                    || video === 'Other options within the scheme' || video.indexOf('When can I receive my pension?') > -1
                    || video === 'Pension from an insurer' || video === 'Flexible Income'">
                    <ul>
                        <li class="title">Playlist</li>
                        <li v-if="(video.indexOf('When can I receive my pension?') > -1 || video === 'Other options within the scheme')"
                                @click="watchVideo('When can I receive my pension? - force')"
                            :class="{ selected: video.indexOf('When can I receive my pension?') > -1 }">
                            <span>2)</span> When can I receive my pension?
                        </li>
                        <li v-if="(video.indexOf('When can I receive my pension?') > -1 || video === 'Other options within the scheme')"
                                @click="watchVideo('Other options within the scheme')"
                            :class="{ selected: video === 'Other options within the scheme' }">
                            <span>3)</span> Other options within the scheme
                        </li>
                        <li v-if="video.indexOf('insurer') > -1" @click="watchVideo('Intro to transfer options - insurer')"
                            :class="{ selected: video.indexOf('Intro') > -1 }">
                            <span>4)</span> Intro to transfer options
                        </li>
                        <li v-if="video.toLowerCase().indexOf('flexible') > -1" @click="watchVideo('Intro to transfer options - flexible')"
                            :class="{ selected: video.indexOf('Intro') > -1 }">
                            <span>4)</span> Intro to transfer options
                        </li>
                        <li v-if="video.indexOf('insurer') > -1" @click="watchVideo('Pension from an insurer - force')"
                            :class="{ 'selected': video.indexOf('Pension') > -1 }">
                            <span>5)</span> Pension from an insurer
                        </li>
                        <li v-if="video.toLowerCase().indexOf('flexible') > -1" @click="watchVideo('Flexible Income - force')"
                            :class="{ selected: video.indexOf('Flexible') > -1 }">
                            <span>6)</span> Flexible Income
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <feedback v-if="$store.getters.showFeedback" @close="$store.commit('showFeedback', false)" @submit="$store.commit('showFeedback', false)"/>
    </div>
</div>
</template>

<script>
import Annuity from "./Annuity";
import SchemePension from "./SchemePension";
// import Drawdown from "./Drawdown";
import Cash from "./Cash";
import FinalQuestions from "./home/final-questions";
import FactFind from "./FactFind";
import Feedback from "./Feedback";
import { TweenMax, TimelineLite } from "gsap";
import "gsap/ScrollToPlugin";
import IntroVideo from "./home/intro-video";
import Intro from "./home/intro";
import Timeline from "./home/timeline";
import Options from "./home/options";
import Vue from "vue";
import VueMq from "vue-mq";
import constants from './../constants.js';
import moment from 'moment';
import captions from './common/captions.vue';
import DrawdownTemp from './DrawdownTemp.vue';
Vue.use(VueMq, {
    breakpoints: {
        mobile: 800,
        tablet: 1100,
        sd: 1450,
        lg: Infinity
    }
});
export default {
    name: "Home",
    props: ["schemeId", "userId"],
    components: {
        Annuity,
        Cash,
        // Drawdown,
        FactFind,
        FinalQuestions,
        Intro,
        IntroVideo,
        Options,
        SchemePension,
        Timeline,
        captions,
        Feedback,
        DrawdownTemp
    },
    data () {
        return {
            animationPlaying: true,
            canScrollDown: false,
            seeSlider: false,
            timeline: null,
            video: null,
            enteredTime: null,
            modalOpenedTime: null,
            sectionOpenedTime: null,
            currentSection: 'intro',
            mobileRecommendedDismissed: false,
            showCaptions: false,
        };
    },
    created () {
        let user = this.userId;
        if (this.userId === undefined) user = "example";
        this.$store.dispatch("getUser", {
            userId: user,
            schemeId: this.schemeDetails.company
        });
        this.onWindowScroll = this.onWindowScroll.bind(this);
    },
    computed: {
        token () {
            return this.$store.getters.token;
        },
        noAccount () {
            return this.$store.getters.getNoAccount;
        },
        introVideoWatched () {
            return this.$store.getters.getIntroVideoWatched;
        },
        user () {
            return this.$store.getters.getUser;
        },
        isSpecial () {
            return this.user.type == 'special_case';
        },
        fullAccess () {
            return !this.isSpecial && !this.isAgeRestricted;
        },
        isAgeRestricted () {
            return this.user.type == 'age_restricted';
        },
        selectedOption: {
            get () {
                return this.getValue("selectedOption");
            },
            set (newValue) {
                this.setValue("selectedOption", newValue);
            }
        },
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        },
        whatThisMeansForMeVisible () {
            return this.getValue("whatThisMeansForMeVisible");
        },
        whatShouldIDoNowVisible () {
            return this.getValue("whatShouldIDoNowVisible");
        }
    },
    mounted () {
        Date.prototype.toJSON = function (){ return moment(this).format(); };
        this.$store.dispatch('setSessionId');
        this.enteredTime = new Date();
        this.$store.commit('postAnalytics', { data: { measure: constants.Overall_Site_Opened, value: new Date() }, token: this.token});
        this.$store.commit('postAnalytics', { data: { measure: constants.Section_Intro_Opened, value: new Date() }, token: this.token });

        const self = this;
        window.addEventListener("beforeunload", function () {
            self.closeVideo();
            self.modalClosing(self.selectedOption);
            // commit rather than dispatch to make the call synchronous
            self.$store.commit('postAnalytics', { data: { measure: constants.Overall_Site_Time, value: (new Date() - self.enteredTime) / 1000 }, token: this.token });
            switch (self.currentSection) {
            case 'intro':
                self.$store.commit('postAnalytics', { data: { measure: constants.Section_Intro_Time, value: (new Date() - self.enteredTime) / 1000 }, token: this.token });
                break;
            case 'options':
                if (self.sectionOpenedTime) {
                    self.$store.commit('postAnalytics', { data: { measure: constants.Section_Options_Time, value: (new Date() - self.sectionOpenedTime) / 1000 }, token: this.token });
                }
                break;
            case 'meansForMe':
                if (self.sectionOpenedTime) {
                    self.$store.commit('postAnalytics', { data: { measure: constants.Section_WhatMeansForMe_Time, value: (new Date() - self.sectionOpenedTime) / 1000 }, token: this.token });
                }
                break;
            case 'finalQuestions':
                if (self.sectionOpenedTime) {
                    self.$store.commit('postAnalytics', { data: { measure: constants.Section_WhatShouldIDoNow_Time, value: (new Date() - self.sectionOpenedTime) / 1000 }, token: this.token });
                }
                break;
            }

            // evt.preventDefault();
            // Google Chrome requires returnValue to be set
            // evt.returnValue = '';

            return '';
        });
        // if (!this.$store.getters.getLoggedIn) this.$router.push('/');
        this.autoCheckHiddenComponents();
        document
            .getElementById("app")
            .addEventListener("scroll", this.onWindowScroll);
    },
    methods: {
        showFeedback ( ){
            localStorage.setItem('feedback', true);
            this.setValue('feedbackExtra', false);
            this.$store.commit('showFeedback', true)
        },
        toggleCaptions () {
            this.showCaptions = !this.showCaptions
            if (this.showCaptions) {
                this.$refs.video.pause();
            } else {
                this.$refs.video.play();
            }
        },
        setSure () {
            this.setValue('whatThisMeansForMeVisible', true);
            this.setValue('whatShouldIDoNowVisible', true);
        },
        emailClicked () {
            this.$store.dispatch('postAnalytics', { measure: constants.Link_EstimateEmailClicked, value: new Date() });
        },
        closeVideo () {
            if (!this.$refs.video) return;
            const timeStamp = this.$refs.video.currentTime;
            switch (this.video) {
            case "Flexible Income":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_FlexibleIncome_Time, value: timeStamp });
                break;
            case "Flexible Income - force":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_FlexibleIncome_Time, value: timeStamp });
                break;
            case "Pension from an insurer":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_PensionFromInsurer_Time, value: timeStamp });
                break;
            case "Pension from an insurer - force":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_PensionFromInsurer_Time, value: timeStamp });
                break;
            case "Intro to transfer options - insurer":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_TransferOptions_Time, value: timeStamp });
                break;
            case "Intro to transfer options - flexible":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_TransferOptions_Time, value: timeStamp });
                break;
            case "When can I receive my pension?":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_OtherOptions_Time, value: timeStamp });
                break;
            case "When can I receive my pension? - force":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_WhenCanIReceive_Time, value: timeStamp });
                break;
            case "Other options within the scheme":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_OtherOptions_Time, value: timeStamp });
                break;
            case "Intro video":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_Intro_Time, value: timeStamp });
                break;
            case "WPS":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_IntroToWPSA_Time, value: timeStamp });
                break;
            }
            this.video = null;
        },
        videoEnded () {
            if (this.video === 'Intro to transfer options - insurer') {
                this.watchVideo('Pension from an insurer - force');
            }
            if (this.video === 'Intro to transfer options - flexible') {
                this.watchVideo('Flexible Income - force');
            }
            if (this.video.IndexOf('When can I receive my pension?') > -1) {
                this.watchVideo('Other options within the scheme');
            }
        },
        getValue (prop) {
            return this.$store.getters.getValue(prop);
        },
        setValue (prop, value) {
            this.$store.dispatch("setValue", { prop, value });
        },
        scrollDown () {
            const timeline = new TimelineLite();
            timeline.add(
                TweenMax.to(document.getElementById("app"), 0.7, {
                    scrollTo: {
                        y:
              (document.getElementById("app").scrollTop - 20) +
              document.getElementById("app").clientHeight
                    }
                })
            );
        },
        autoCheckHiddenComponents () {
            if (this.$mq === "tablet" || this.$mq === "mobile") {
                this.setValue("combinationWatched", true);
                this.setValue("combinationOpened", true);
            }
        },
        watchVideo (option) {
            this.closeVideo();
            this.video = option;
            switch (option) {
            case "Flexible Income":
                if (this.getValue('introToTransferOptionsWatched')) {
                    this.setValue("drawdownWatched", true);
                    this.$store.dispatch('postAnalytics', { measure: constants.Video_FlexibleIncome_Opened, value: new Date() });
                } else {
                    this.watchVideo('Intro to transfer options - flexible');
                }
                break;
            case "Flexible Income - force":
                this.setValue("drawdownWatched", true);
                this.video = 'Flexible Income';
                this.$store.dispatch('postAnalytics', { measure: constants.Video_FlexibleIncome_Opened, value: new Date() });
                break;
            case "Pension from an insurer":
                if (this.getValue('introToTransferOptionsWatched')) {
                    this.setValue("annuityWatched", true);
                    this.$store.dispatch('postAnalytics', { measure: constants.Video_PensionFromInsurer_Opened, value: new Date() });
                } else {
                    this.watchVideo('Intro to transfer options - insurer');
                }
                break;
            case "Pension from an insurer - force":
                this.setValue("annuityWatched", true);
                this.video = 'Pension from an insurer';
                this.$store.dispatch('postAnalytics', { measure: constants.Video_PensionFromInsurer_Opened, value: new Date() });
                break;
            case "Intro to transfer options - insurer":
                this.setValue("introToTransferOptionsWatched", true);
                this.$store.dispatch('postAnalytics', { measure: constants.Video_TransferOptions_Opened, value: new Date() });
                break;
            case "Intro to transfer options - flexible":
                this.setValue("introToTransferOptionsWatched", true);
                this.$store.dispatch('postAnalytics', { measure: constants.Video_TransferOptions_Opened, value: new Date() });
                break;
            case "When can I receive my pension?":
                if (this.getValue('schemePensionWatched')) {
                    this.watchVideo('Other options within the scheme');
                } else {
                    this.watchVideo('When can I receive my pension? - force');
                }
                break;
            case "When can I receive my pension? - force":
                this.setValue("schemePensionWatched", true);
                this.$store.dispatch('postAnalytics', { measure: constants.Video_WhenCanIReceive_Opened, value: new Date() });
                break;
            case "Other options within the scheme":
                this.setValue("otherOptionsWatched", true);
                this.$store.dispatch('postAnalytics', { measure: constants.Video_OtherOptions_Opened, value: new Date() });
                break;
            case "Intro video":
                this.setValue("introVideoWatched", true);
                this.$store.dispatch('postAnalytics', { measure: constants.Video_Intro_Opened, value: new Date() });
                break;
            case "WPS":
                this.$store.dispatch('postAnalytics', { measure: constants.Video_IntroToWPSA_Opened, value: new Date() });
            }
            this.$nextTick(() => {
                if (this.$refs.video) {
                    this.$refs.video.src = this.$refs.video.children[0].src;
                    if (this.showCaptions) {
                        this.$refs.video.pause();
                    }
                }
            });
        },
        selectOption (option) {
            this.selectedOption = option;
        },
        optionComplete () {
            switch (this.selectedOption) {
            case "Combination":
                this.combinationOpened = true;
                break;
            case "Cash":
                this.setValue("cashOpened", true);
                break;
            case "Flexible Income":
                this.setValue("drawdownOpened", true);
                break;
            case "Pension from an insurer":
                this.setValue("annuityOpened", true);
                break;
            case "Scheme Pension":
                this.setValue("schemePensionOpened", true);
                this.setValue("drawdownOpened", true);
                break;
            }
            this.selectedOption = "";
        },
        watchedIntroVideo () {
            this.setValue("showIntro", true);
            this.$nextTick(() => {
                this.timeline = new TimelineLite({
                    onComplete: () => {
                        this.animationPlaying = false;
                    }
                });
                this.timeline.add(
                    TweenMax.to(document.getElementById("app"), 0.7, {
                        scrollTo: { y: ".intro .white-curve", offsetY: 130 }
                    })
                );
            });
            this.$store.dispatch('postAnalytics', { measure: constants.Section_Options_Opened, value: new Date() });
            this.$store.dispatch('postAnalytics', { measure: constants.Section_Intro_Time, value: (new Date() - this.enteredTime) / 1000 });
            this.sectionOpenedTime = new Date();
            this.currentSection = 'options';
        },
        showMeansForMe () {
            this.$store.dispatch('postAnalytics', { measure: constants.Section_WhatMeansForMe_Opened, value: new Date() });
            if (this.sectionOpenedTime) {
                this.$store.dispatch('postAnalytics', { measure: constants.Section_Options_Time, value: (new Date() - this.sectionOpenedTime) / 1000 });
            }
            this.sectionOpenedTime = new Date();
            this.currentSection = 'meansForMe';
        },
        showWhatDoIDoNow () {
            this.$store.dispatch('postAnalytics', { measure: constants.Section_WhatShouldIDoNow_Opened, value: new Date() });
            if (this.sectionOpenedTime) {
                this.$store.dispatch('postAnalytics', { measure: constants.Section_WhatMeansForMe_Time, value: (new Date() - this.sectionOpenedTime) / 1000 });
            }
            this.sectionOpenedTime = new Date();
            this.currentSection = 'finalQuestions';
        },
        onWindowScroll () {
            const a = document.getElementById("app");
            if (!this.canScrollDown) {
                window.setTimeout(() => {
                    this.canScrollDown = a.scrollTop < a.scrollHeight - a.clientHeight && a.scrollTop < 1200;
                }, 3000);
            } else {
                this.canScrollDown = a.scrollTop < a.scrollHeight - a.clientHeight && a.scrollTop < 1200;
            }
        },
        modalClosing (modalName) {
            switch (modalName) {
            case 'Pension from an insurer':
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_PensionFromInsurer_Time, value: (new Date() - this.modalOpenedTime) / 1000 });
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_PensionFromInsurer_Progress, value: this.$refs.annuityModal.currentQuestion !== undefined ? this.$refs.annuityModal.currentQuestion + 1 : 5 });
                break;
            case 'Scheme Pension':
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_SchemePension_Time, value: (new Date() - this.modalOpenedTime) / 1000 });
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_SchemePension_Progress, value: this.$refs.schemePensionModal.currentQuestion + 1 });
                break;
            case 'Flexible Income':
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_FlexibleIncome_Time, value: (new Date() - this.modalOpenedTime) / 1000 });
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_FlexibleIncome_Progress, value: this.$refs.drawdownModal.currentQuestion + 1 });
                break;
            case 'wps':
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_WPSA_Time, value: (new Date() - this.modalOpenedTime) / 1000 });
                break;
            }
        },
        wpsaModalToggled (val) {
            this.selectedOption = val ? 'wps' : '';
        }
    },
    beforeDestroy () {
        document
            .getElementById("app")
            .removeEventListener("scroll", this.onWindowScroll);
    },
    watch: {
        user () {
            if (!this.user) {
                return;
            }
        },
        whatThisMeansForMeVisible () {
            this.$nextTick(() => {
                this.animationPlaying = true;
                this.timeline = new TimelineLite({
                    onComplete: () => {
                        this.animationPlaying = false;
                    }
                });
                this.timeline.add(
                    TweenMax.to(document.getElementById("app"), 0.7, {
                        scrollTo: { y: "#whatdoesthismean", offsetY: 20 }
                    })
                );
            });
        },
        whatShouldIDoNowVisible () {
            if (this.whatShouldIDoNowVisible) {
                this.$nextTick(() => {
                    this.animationPlaying = true;
                    this.timeline = new TimelineLite({
                        onComplete: () => {
                            this.animationPlaying = false;
                        }
                    });
                    // var nextStepsText = new window.SplitText("h2.next-steps");
                    this.timeline.add(
                        TweenMax.to(document.getElementById("app"), 0.7, {
                            scrollTo: "#finalQuestions"
                        })
                    );
                });
            }
        },
        selectedOption (newValue, oldValue) {
            switch (newValue) {
            case 'Pension from an insurer':
                this.modalOpenedTime = new Date();
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_PensionFromInsurer_Opened, value: new Date() });
                break;
            case 'Scheme Pension':
                this.modalOpenedTime = new Date();
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_SchemePension_Opened, value: new Date() });
                break;
            case 'Flexible Income':
                this.modalOpenedTime = new Date();
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_FlexibleIncome_Opened, value: new Date() });
                break;
            case 'wps':
                this.modalOpenedTime = new Date();
                this.$store.dispatch('postAnalytics', { measure: constants.Modal_WPSA_Opened, value: new Date() });
                break;
            }

            this.modalClosing(oldValue);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
@import "./../scss/mixins.scss";
.loaded {
  background: $nationalGridBlue;
}
.scroll-holder {
    background-color: $nationalGridOrange;
    bottom: 2rem;
    box-shadow: rgba(0, 0, 0, 0.2) -2px 2px 2px;
    height: 50px;
    left: calc(50% - 25px);
    position: fixed;
    width: 50px;
    z-index: 99;
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
    @include responsive(mobile) {
        display: none;
    }
    .scroll-down {
        border-bottom: 2px solid white;
        border-left: 2px solid white;
        bottom: 2rem;
        height: 2rem;
        margin: auto;
        transform: rotate(-45deg);
        transition: transform 0.5s;
        width: 2rem;
        z-index: 99;
    }
}
.container {
  @include responsive(tablet) {
    padding: 1rem 2rem 1rem 2rem;
  }
}
.caption-toggle {
    position: absolute;
    margin: 1rem;
    left: 0rem;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    color: #fff;
    .option {
        display: flex;
        align-items: center;
        padding: 0.5rem 0.75rem 0.5rem 0.5rem;
        img {
            margin-right: 0.5rem;
            height: 30px;
            width: 30px;
        }
        &.selected {
            background: $highlightColour;
        }
    }
}

.give-feedback {
    text-decoration: underline;
    position: absolute !important;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    &:hover {
        color: $highlightColour;
    }
}

.modal-backdrop {
  position: fixed;
  content: "";
  width: 100vmax;
  top: 0;
  left: 0;
  height: 100vmax;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}
input[type="checkbox"] {
  display: none;
}
input[type="radio"] {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
input:checked {
  + .card {
    background: $highlightColour;
    color: white;
    h4 {
      color: white;
    }
    .amount {
      color: white;
    }
  }
}
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  padding: 1rem;
  margin: 0.5rem;

  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px 1px;
  background: #fff;
  @include responsive(mobile) {
    width: 100%;
  }
  transition: transform 0.5s;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
  p {
    padding: 0;
  }
  h4 {
    font-weight: 400;
    text-align: center;
    color: #333;
    font-size: 1.1rem;
  }
  &.selected {
    background: $nationalGridLightBlue;
    color: white;
    h4 {
      color: white;
    }
    .amount {
      color: white;
    }
  }
}

.mini-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  transform: translateY(-50%) translateX(-50%);
  background: white;
  padding: 2rem;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.7) 0 0 0 100rem;
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 100vmax;
    animation: growIn 0.5s;
  animation-fill-mode: both;
  > * {
      z-index: 2;
      position: relative;
  }
  &:before {
      content: '';
      z-index: 0;
      position: fixed;
      left: 0;
      top: 0;
      height: 200vh;
      width: 200vw;
      background: transparent;
      transform: translate(-50%, -50%);
  }
  &.mobile-recommended {
      display:  none;
      @media(max-width: 760px) {
          display: block;
          width: 80vw;
      }
  }
}

.yes-no {
  overflow: hidden;
  text-align: center;
  width: 100%;
  .option {
    display: inline-block;
    margin: 0.3rem;
  }
  input {
    opacity: 0;
    pointer-events: none;
    position: fixed;
        left: -9999px;
    &:not(:checked) ~ label {
      background: #ccc;
      box-shadow: none;
      transform: scale(1);
      z-index: 0;
    }
  }
  label {
    display: block;
    margin: 0;
    padding: 1rem 2rem;
    min-width: auto;
    width: 100%;
    background: $highlightColour;
    text-align: center;
    color: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    position: relative;
    transition: transform 0.25s;
    z-index: 1;
    &:hover {
      cursor: pointer;
    }
  }
}

.btn {
    background: $nationalGridLightBlue;
    color: white;
    padding: 1rem 0.5rem;
    margin-top: 0.5rem;
    display: inline-block;
    width: 100%;
    text-align: center;
    transition: transform 0.5s, box-shadow 0.5s;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px;
    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
    }

  &.small {
    width: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2rem;
    padding: 1rem 2rem;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    background: $nationalGridGreen;
    text-shadow: rgba(0, 0, 0, 0.5) 0 0 8px;
    transform-origin: 0% 50%;
    font-size: 1.1rem;
    &:hover {
      transform: translateY(-2px) translateX(-50%);
      box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
    }
    &.greyed {
      box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 4px;
      background: #aaa;
    }
    &.introvideo {
      left: 0;
      transform: none;
      margin-top: 2rem;
    }
  }
  &.yes {
    background: #aaa;
  }
}
.no-account {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
    height: calc(100vh - 138px);
    p {
        background: #f4f4f4;
        padding: 1.5rem;
    }
}
@keyframes growIn {
  from {
    opacity: 0;
    box-shadow: rgba(0, 0, 0, 0.7) 0 0 0 100rem;
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 100vmax;
  }
  to {
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.7) 0 0 0 100rem;
    box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 100vmax;
  }
}

#video {
  position: fixed;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translateY(-50%) translateX(-50%);
  min-width: 90vw;
  height: auto;
  z-index: 99;
  left: 50%;
  background: #000;
  box-shadow: rgba(0, 0, 0, 0.7) 0 0 0 100rem;
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 100vmax;
  animation: growIn 0.5s;
  animation-fill-mode: both;
  text-align: center;
  video {
    height: auto;
    width: 90vw;
    background: #000;
    padding: 1rem;
    max-height: 80vh;
  }
  .has-playlist video {
    width: calc(90vw - 200px);
  }
  .playlist {
    width: 200px;
    background: #333;
    li {
      color: white;
      padding: 0.75rem;
      text-align: left;
      border-bottom: 1px solid #555;
      transition: background 0.5s, color 0.5s;
      &.selected {
        background: $nationalGridGreen;
        color: #fff;
      }
      &:hover {
        cursor: pointer;
        background: $highlightColour;
        color: #fff;
      }
      span {
        font-weight: 700;
      }
      &.title {
        font-size: 1.1rem;
        background: rgba(0, 0, 0, 0.2);
        border-bottom: none;
      }
    }
  }
  .flex {
    display: flex;
  }
  .video-title {
    padding: 1rem;
    background: $nationalGridBlue;
    color: white;
    font-size: 1.2rem;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    padding: 1rem;
    font-size: 1.2rem;
    background: $nationalGridLightBlue;
    &:hover {
      cursor: pointer;
      background: lighten($nationalGridLightBlue, 5);
    }
  }
}

.skip {
  position: fixed;
  right: 2rem;
  z-index: 2;
  background: $highlightColour;
  color: white;
  padding: 0.5rem;
  bottom: 1rem;
  &:hover {
    cursor: pointer;
    background: lighten($highlightColour, 5);
  }
}

h3 {
  margin-top: 2rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  &.four-options {
    max-width: 1400px;
  }
}

h2 {
  font-size: 1.4rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  &.options-you-have {
    margin-top: 47vh;
  }
  &.next-steps {
    position: relative;
    z-index: 99;
  }
}

.highlight {
  font-weight: 600;
}

@keyframes dropIn {
  from {
    transform: translateY(-25%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.highlight-color {
  color: $highlightColour;
}

.no-account {
    min-height: 400px;
    padding: 1rem;
    padding-top: 3rem;
    font-size: 1.2rem;
    text-align: center;
}
</style>
