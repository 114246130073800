import axios from 'axios';

export default {
    getPermissionLevel (success, fail) {
        axios.get('/api/auth/').then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
};