<template>
<modal title="Cash" @close="$emit('close')" :shortIntro="!currentQuestion || currentQuestion === 5">
  <template slot="intro">
    <p v-if="currentQuestion && currentQuestion !== 5">
        You will pay
        <span class="amount">£{{ formatAmount(taxDueFromIncomePlusCetv - taxDueFromIncomeOnly) }} </span>
        of tax on your Cash Equivalent Transfer Value of <span class="amount">£{{ formatAmount(cetv) }}. </span>
        This will leave you with a balance of <span class="amount">£{{ formatAmount(transferValue) }}</span>
    </p>
  </template>
  <template slot="content">
    <questions-flow ref="questions" @complete="submit">
      <question title="Introduction" :animateText="true">
        <div class="container left">
          <p class="animate">You will pay
              <span class="amount">£{{ formatAmount(taxDueFromIncomePlusCetv - taxDueFromIncomeOnly) }} </span>of tax on your Cash Equivalent Transfer Value of
              <span class="amount">£{{ formatAmount(cetv) }}. </span>This will leave you with a balance of
              <span class="amount">£{{ formatAmount(transferValue) }}</span>
            </p>
          <p class="animate">This assumes you have no other taxable income. Continue to tailor this to your circumstances.</p>
          <p class="animate">This calculator is based on general information and the standard personal allowance and tax bands used by
              HMRC for 6 April 2018 to 5 April 2019 tax year using UK rates for England, Wales and Northern Ireland. Scottish income tax thresholds may differ.
          </p>
        </div>
      </question>
      <question title="Expected Salary" :animateText="true">
        <div class="container left">
          <p class="animate">Enter salary from employment expected over year to 6 April 2019</p>
          <div class="post-animate">
            <div>
              <vue-slider v-model="expectedSalary" :real-time="true" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="0" :tooltip="false" :max="1000" :dotSize="28">
                <template slot="label" slot-scope="{ label, active }"><span class="vue-slider-piecewise-label" v-if="label % 100 === 0">£{{ formatAmount(label * 100) }}</span></template>
                <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 100 === 0"></span></template>
              </vue-slider>
            </div>
            <p class="slider-amount"> <span class="amount">£{{ formatAmount(expectedSalary * 100) }}</span></p>
          </div>
        </div>
      </question>
      <question title="Other Pension Income" :animateText="true">
        <div class="container left">
          <p class="animate">Enter other pension income (inc any state pension) expected over year to 6 April 2019</p>
          <div class="post-animate">
            <div>
              <vue-slider v-model="otherPensionIncome" :real-time="true" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="0" :tooltip="false" :max="1000" :dotSize="28">
                <template slot="label" slot-scope="{ label, active }"><span class="vue-slider-piecewise-label" v-if="label % 100 === 0">£{{ formatAmount(label * 100) }}</span></template>
                <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 100 === 0"></span></template>
              </vue-slider>
              <p class="slider-amount"> <span class="amount">£{{ formatAmount(otherPensionIncome * 100) }}</span></p>
            </div>
          </div>
        </div>
      </question>
      <question title="Other Income" :animateText="true">
        <div class="container left">
          <p class="animate">Enter other investment or rental income on which you pay tax</p>
          <div class="post-animate">
            <div>
              <vue-slider v-model="otherIncome" :real-time="true" :piecewise="true" :piecewiseLabel="true" :speed="0" :min="0" :tooltip="false" :max="1000" :dotSize="28">
                <template slot="label" slot-scope="{ label, active }"><span class="vue-slider-piecewise-label" v-if="label % 100 === 0">£{{ formatAmount(label * 100) }}</span></template>
                <template slot="piecewise" slot-scope="{ label, active }"><span class="vue-slider-piecewise-dot year" :class="{ active: active }" v-if="label % 100 === 0"></span></template>
              </vue-slider>
              <p class="slider-amount"> <span class="amount">£{{ formatAmount(otherIncome * 100) }}</span></p>
            </div>
          </div>
        </div>
      </question>
      <question title="Dependant benefits" :animateText="true">
        <div class="container">
          <p class="animate sub-title">What will be left to my spouse and dependants?</p>
          <p class="animate">Taking all of your benefit as a cash lump sum you will leave you with no dependant pension cover.</p>
          <p class="animate">You will have to make separate provisions for your dependants.</p>
        </div>
      </question>
      <question title="Summary" :animateText="true">
        <div class="sub-groups">
          <div class="sub-group">
            <p class="animate sub-title">Regular Income</p>
            <p class="animate">None</p>
          </div>
          <div class="sub-group">
            <p class="animate sub-title">Initial lump sum</p>
            <p class="animate">Estimated £{{ formatAmount(cetv) }} but subject to an estimated tax of £{{ formatAmount(taxDueFromIncomePlusCetv - taxDueFromIncomeOnly) }}</p>
          </div>
          <div class="sub-group">
            <p class="animate sub-title">Benefits for my dependants</p>
            <p class="animate">None</p>
          </div>
        </div>
      </question>
    </questions-flow>
  </template>
</modal>

</template>

<script>
import QuestionsFlow from './common/QuestionsFlow';
import Question from './common/Question';
import Slider from './common/Slider';
import vueSlider from 'vue-slider-component';
import { TweenMax, TimelineLite } from "gsap";
import { debounce } from 'lodash';
import Modal from  './common/Modal';

export default {
    name: 'Cash',
    components: { QuestionsFlow, Question, Slider, vueSlider, Modal },
    data () {
        return {
            showQuestions: false,
            otherIncome: 0,
            otherPensionIncome: 0,
            expectedSalary: 0,
            mounted: false,
            riskPercent: 1
        };
    },
    methods: {
        formatAmount (amount) {
            return Math.round(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        zeroPercentTax (amount) {
            var paThresholdLower = 100000;
            var paThresholdUpper = 123000;
            var personalAllowancePa = 11509;
            return Math.min(amount, (amount < paThresholdLower ?
                personalAllowancePa : amount > paThresholdUpper ?
                    0 : personalAllowancePa - (amount - paThresholdLower) * 0.5));
        },
        twentyPercentTax (amount, zeroPercentTax) {
            var higherRateThreshold = 33500;
            return Math.max(Math.min(higherRateThreshold, amount - zeroPercentTax), 0);
        },
        fortyPercentTax (amount, zeroPercentTax, twentyPercentTax) {
            var additionalRateThreshold = 150000;
            return Math.max(Math.min(additionalRateThreshold - twentyPercentTax - zeroPercentTax, amount - twentyPercentTax - zeroPercentTax));
        },
        fortyFivePercentTax (amount, zeroPercentTax, twentyPercentTax, fortyPercentTax) {
            return amount - zeroPercentTax - twentyPercentTax - fortyPercentTax;
        },
        submit () {
            this.$store.dispatch('setCashTaxAmount', this.taxDueFromIncomePlusCetv - this.taxDueFromIncomeOnly);
            this.$emit('submit');
        },
        animateAmount: debounce(function () {
            var timeline = new TimelineLite();
            timeline.add(TweenMax.fromTo('.intro .amount', 0.5,  { opacity: 1, scale: 1.5, force3D: true }, { scale: 1, opacity: 1, y: 0 }));
        }, 150)
    },
    computed: {
        cetv () {
            return this.$store.getters.getCashAmount;
        },
        currentQuestion () {
            if (this.mounted && this.$refs.questions) {
                return this.$refs.questions.questionNo;
            }
        },
        taxableIncome () {
            return this.expectedSalary * 100 + this.otherIncome * 100 + this.otherPensionIncome * 100;
        },
        taxDueFromIncomeOnly () {
            var taxIncome = this.taxableIncome;
            var zeroPercentTax = this.zeroPercentTax(taxIncome);
            var twentyPercentTax = this.twentyPercentTax(taxIncome, zeroPercentTax);
            var fortyPercentTax = this.fortyPercentTax(taxIncome, zeroPercentTax, twentyPercentTax);
            var fortyFivePercentTax = this.fortyFivePercentTax(taxIncome, zeroPercentTax, twentyPercentTax, fortyPercentTax);
            var taxDueFromIncomeOnly = zeroPercentTax * 0 + twentyPercentTax * 0.2 + fortyPercentTax * 0.4 + fortyFivePercentTax * 0.45;
            return taxDueFromIncomeOnly;
        },
        taxDueFromIncomePlusCetv () {
            var taxableIncomePlusCetv = this.taxableIncome + this.cetv - this.cetv * 0.25;
            var zeroPercentTax = this.zeroPercentTax(taxableIncomePlusCetv);
            var twentyPercentTax = this.twentyPercentTax(taxableIncomePlusCetv, zeroPercentTax);
            var fortyPercentTax = this.fortyPercentTax(taxableIncomePlusCetv, zeroPercentTax, twentyPercentTax);
            var fortyFivePercentTax = this.fortyFivePercentTax(taxableIncomePlusCetv, zeroPercentTax, twentyPercentTax, fortyPercentTax);
            var taxDueFromIncomePlusCetv = zeroPercentTax * 0 + twentyPercentTax * 0.2 + fortyPercentTax * 0.4 + fortyFivePercentTax * 0.45;
            return taxDueFromIncomePlusCetv;
        },
        transferValue () {
            return this.cetv - this.taxDueFromIncomePlusCetv + this.taxDueFromIncomeOnly;
        }
    },
    mounted () {
        this.mounted = true;
        var timeline = new TimelineLite();
        timeline.add(TweenMax.from(".scheme-pension-container h2", 1, { opacity: 0, y: -5, scaleX: 0.5, scaleY: 0.5 }));
    },
    watch: {
        transferValue () {
            this.animateAmount();
        }
    }
};
</script>


<style scoped lang="scss">
@import './../scss/variables.scss';
@import './../scss/mixins.scss';

p.slider-amount {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1rem;
    width: 100%;
    display: inline-block;
}

.amount {
    color: $nationalGridOrange;
    font-weight: 700;
}


@include responsive (mobile) {
    .vue-slider-piecewise-label {
        display: none !important;
    }
}

</style>
