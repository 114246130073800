import analyticsApi from './../../api/analytics';
import guid from './../../mixins/guid';
const state = {
    sessionId: '',
    showFeedback: false
};

const getters = {
    showFeedback (s) {
        return s.showFeedback;
    }
};

const actions = {
    setSessionId ({ commit}) {
        commit('setSessionId', guid.methods.generateGuid());
    },
    postAnalytics (context, data) {
        data.sessionId = state.sessionId;
        analyticsApi.postAnalytics(data);
    }
};

const mutations = {
    postAnalytics (state, { data, token }) {
        data.sessionId = state.sessionId;
        analyticsApi.postAnalyticSync(data, token);
    },
    setValue (state, { prop, value }) {
        state[prop] = value;
    },
    setSessionId (state, value) {
        state.sessionId = value;
    },
    showFeedback (state, val) {
        state.showFeedback = val;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
