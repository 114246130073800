import axios from 'axios';
export default {
    getUser (id, scheme, success, fail) {
        axios.get('/api/users/'+ id + '/' + scheme).then((response) => {
            success(response.data);
        }, (response) => {
            fail(response);
        });
    },
    getIsRegistered () {
        return axios.get('/api/isRegistered/').then((response) => {
            return response.data;
        }, (response) => {
            return response;
        });
    },
    getCanRegister () {
        return axios.get('/api/canRegister/').then((response) => {
            return response.data;
        }, (response) => {
            return response;
        });
    },
    registerUser (registrationDetails) {
        return axios.post('/api/register/', registrationDetails).then((response) => {
            return response.data;
        }, (response) => {
            return response;
        });
    }
};