<template>
<div id="app" v-if="schemeDetails" :class="{ 'with-bar': permissionLevel === 1 || permissionLevel === 3}">
    <div class="admin-toolbar" v-if="!hideHeader && permissionLevel === 1 || permissionLevel === 3">
        <div class="search">
            <label for="selectUser">Search user by:</label>
            <select id="selectUser" v-model="searchMethod">
                <option value="NI_Number">NI Number</option>
                <option value="Pension_Number">Scheme number</option>
            </select>
            <input type="text" v-model="search" @keyup.enter="getUser" @focus="clearUser"/>
            <button @click="getUser">Load user</button>
        </div>
        <div class="links" v-if="permissionLevel === 3">
            <router-link to="/planner/">Home</router-link>
            <router-link to="/planner/analytics/stats">View stats</router-link>
            <router-link to="/planner/analytics/feedback">View feedback</router-link>
        </div>
    </div>
    <page-header v-if="!hideHeader" :admin="true" :permissionLevel="permissionLevel" :logoutFunction="logout"/>
    <div class="content">
        <router-view permissionLevel="permissionLevel"/>
    </div>
    <page-footer />
    <print v-if="isPrint" />
</div>
</template>

<script>
import pageHeader from '~/components/common/layout/pageHeader';
import pageFooter from '~/components/common/layout/pageFooter';
import print from '~/components/print/print';
import Analytics from './components/analytics.vue';
import Feedback from './components/feedback.vue';
import Login from './components/login.vue';
import moment from 'moment';
import authApi from './api/auth';
import axios from 'axios';
import auth from './services/authentication';
import authentication from './services/authentication';
// permissions
// 1 = users only
// 2 = charts only
// 3 = charts and users
export default {
    name: 'app',
    components: {
        pageHeader,
        pageFooter,
        print
    },
    data () {
        return {
            selectedUser: null,
            search: null,
            searchMethod: 'NI_Number',
            permissionLevel: 0,
            token: null
        };
    },
    created () {
        this.$router.addRoutes([
            {
                path: '/',
                name: 'Login',
                component: Login,
                props: true,
                meta: {
                    hideHeader: true
                }
            },
            {
                path: '/:schemeId/analytics/stats',
                name: 'Stats',
                component: Analytics,
                props: true
            },
            {
                path: '/:schemeId/analytics/feedback',
                name: 'Feedback',
                component: Feedback,
                props: true
            }
        ]);
        var pathArray = window.location.pathname.split('/');
        if (pathArray.length <= 1 || !pathArray[1].length) {
            this.$store.dispatch('getSchemeDetails', 0);
        } else {
            this.$store.dispatch('getSchemeDetails', pathArray[1]);
        }
        let authResponse = localStorage.getItem('hello');
        if (authResponse && authResponse !== '{}') {
            authResponse = JSON.parse(authResponse).b2clogin;
            this.token = authResponse.access_token;
            if (this.token && !authResponse.error) {
                this.$store.commit('setLoggedIn', true);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
            }


            if (moment.unix(authResponse.expires) < moment()) {
                this.$store.commit('setLoggedIn', false);
                if (this.$route.name !== 'Login') window.location.href = '/';
            }
        }
        if (!this.getLoggedIn) {
            if (this.$route.name !== 'Login') window.location.href = '/';
        }

    },
    mounted () {
        if (this.$route.name !== 'Login' && !this.permissionLevel) {
            authApi.getPermissionLevel((result) => {
                this.permissionLevel = result;
                if (this.permissionLevel === 2) this.$router.push('/planner/analytics/stats');
                if (this.permissionLevel === 4) this.$router.push('/planner/analytics/feedback');
            });
        }
    },
    methods: {
        logout () {
            auth.signOut();
        },
        getUser () {
            this.$store.dispatch('getUser', { userId: this.search, schemeId: this.searchMethod});
        },
        clearUser () {
            this.$store.commit('clearUser');
            this.$store.commit('setNoAccount', true);
        }
    },
    watch: {
        '$route.name': function () {
            if (this.$route.name !== 'Login' && !this.permissionLevel) {
                authApi.getPermissionLevel((result) => {
                    this.permissionLevel = result;
                    if (this.permissionLevel === 2) this.$router.push('/planner/analytics/stats');
                    if (this.permissionLevel === 4) this.$router.push('/planner/analytics/feedback');
                });
            }
        },
        isIdle () {
            if (this.isIdle) this.logout();
        }
    },
    computed: {
        isIdle () {
            return this.isAppIdle;
        },
        getLoggedIn () {
            return this.$store.getters.getLoggedIn;
        },
        isPrint () {
            return this.$store.state.print.show;
        },
        schemeDetails () {
            return this.$store.getters.getSchemeDetails;
        },
        hideHeader () {
            return this.$route.meta.hideHeader;
        },
        users () {
            return this.$store.getters.getUsersList;
        }
    },
};
</script>

<style lang="scss">
@import '../../RetirementPlanner.VueCore/src/scss/reset.scss';
@import '../../RetirementPlanner.VueCore/src/scss/variables.scss';
@import '../../RetirementPlanner.VueCore/src/scss/mixins.scss';
html,
body {
    height: 100%;
    font-family: $primaryFont;
    background: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    overflow: hidden;
}
#app {
    height: 100%;
    background: white;
    overflow: auto;
}
.container {
    padding: 1rem 5rem 1rem 5rem;
    width: 100%;
    max-width: 1700px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @include responsive (mobile) {
        padding: 1rem 1rem 0 1rem;
    }
}
p {
    line-height: 1.5;
    margin: 1rem 0;
}

input[type="text"],
input[type="number"],
select,
textarea,
[type="password"] {
    position: relative;
    display: inline-block;
    padding: 0.5rem;
    border: 1px solid $borderColour;
    width: 100%;
    font-family: $primaryFont;
    background: #fbfbfb;
    transition: box-shadow 0.5s;
    box-sizing: border-box;
    resize: none;
    &:focus {
        outline: none;
        box-shadow: inset rgba(0, 0, 0, 0.14) 2px 2px 6px 0px;
    }
    &.small {
        max-width: 200px;
    }
    &.medium {
        max-width: 33%;
    }

    &:disabled {
        background: $borderColour;
        color: #666;
    }
}

.left {
    text-align: left;
}

.flex {
    display: flex;
}

.sub-groups {
    display: flex;
    @media(max-width: 700px) {
        flex-wrap: wrap;
    }
    &.full-width {
        flex-wrap: wrap;
        .row {
            width: 100%;
            display: flex;
        }
    }
    .sub-group {
        margin: 1rem;
        background: $highlightColour;
        color: white;
        padding: 1rem;
        flex-grow: 1;
        font-size: 1.1rem;
        width: 100%;
        max-width: 33%;
        @include responsive(tablet) {
            margin: 1rem 0.2rem;
        }
        @media(max-width: 700px) {
            max-width: 100%;
        }
        .sub-title {
            font-weight: 700;
            background: rgba(0, 0, 0, 0.1);
            margin: -1rem;
            padding: 1rem;
            margin-bottom: 1rem;
        }
        &:last-of-type {
            background: darken($nationalGridGreen, 2);
        }
        &:first-of-type {
            background: $nationalGridLightBlue;
        }
        &.orange {
            background: $highlightColour;
        }
        &.green {
            background: darken($nationalGridGreen, 2);
        }
        &.pink {
            background: $nationalGridPink;
        }
        &.blue {
            background: $nationalGridLightBlue;
        }
        p.left {
            text-align: left;
        }
    }
}

.left {
    text-align: left;
}

.centre {
    text-align: center;
}

.loading {
    height: 4rem;
    width: 4rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border-left: 0px solid $highlightColour;
    border-top: 3px solid $highlightColour;
    border-right: 2px solid $highlightColour;
    border-bottom: 1px solid $highlightColour;
    animation: rotate infinite 1s linear;
}

.vue-slider-wrap, .vue-slider-component {
    .vue-slider-dot {
        background: $nationalGridGreen !important;
    }
    &:hover .vue-slider-dot {
        background: $highlightColour !important;
    }
    .vue-slider {
        background: #ccc !important;
    }
    .vue-slider-process {
        background: $nationalGridGreen !important;
        background-color: $nationalGridGreen !important;
    }
    &:hover .vue-slider-process {
        background: $highlightColour !important;
    }
    .hide {
        display: none !important;
    }
    .vue-slider-piecewise-dot.year {
        height: 1rem;
        width: 1rem;
        background: #ccc !important;
        &.active {
            background: $nationalGridGreen !important;
            background-color: $nationalGridGreen !important;
        }
    }
    &:hover .vue-slider-piecewise-dot.year.active {
        background: $highlightColour !important;
        background-color: $highlightColour !important;
    }
    .vue-slider-piecewise-item:first-child .vue-slider-piecewise-dot, .vue-slider-piecewise-item:last-child .vue-slider-piecewise-dot {
        visibility: visible !important;
    }
}

.admin-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #222;
    width: 100%;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    label {
        margin-left: 1rem;
        color: white;
        margin-right: 0.5rem;
    }
    a {
        color: white;
        text-decoration: underline;
        margin-right: 1rem;
    }
    select, input {
        width: auto;
        background: rgba(255, 255, 255, 0.4);
        color: #333;
        border: 0;
        margin-left: 0.5rem;
    }
    button {
        margin-left: 0.5rem;
        background: $nationalGridPink;
        color: white;
        padding: 0.5rem;
        border: 0;
    }
}

#app.with-bar {
    header {
        margin-top: 38px;
    }
}

@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
</style>
